import { useState, useContext, Dispatch, SetStateAction } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import DashboardCard from 'pages/dashboard/DashboardCard'
import Divider from '@material-ui/core/Divider'
import EmptyBox from 'cf-components/EmptyBox'
import { accountContactsFilter, Contact, contactTypeFilter } from 'classes/contacts'
import { AccountPageContext } from './AccountPageProvider'
import SkeletonTableLoader from 'library/loading/skeleton/SkeletonTableLoader'
import { Icon, Tabs, Tooltip, Typography } from 'library/materialUI'
import { fromProspectIcon, iqlIcon, organicIcon } from 'pages/people/ContactTableFilters'
import { ScrollBox } from 'cf-components/ScrollBox'
import SkeletonLoader from 'library/loading/skeleton/SkeletonLoader'
import theme from 'theme'
import Label from 'library/typography/Label'
import ProspectModal from 'library/modals/ProspectModal'
import IntegrationModal from 'pages/people/IntegrationModal'
import IconButton from '@material-ui/core/IconButton'
import { Avatar, getInitials } from 'cf-components/Avatars'
import { Link } from 'react-router-dom'
import SnapshotUpgradePromptModal, { contactUpgradeMessage } from 'pages/billing/SnapshotUpgradePromptModal'
import { ShareContext } from 'share-context'
import { SNAPSHOT_PLAN_NAME } from 'library/constants'

export const prospectToContactIcon = (
  <Tooltip
    title='Contact clicked through from a tracked email link'
  >
    <div style={{ display: 'flex' }}>
      <Icon icon='iql' color='white' size='md' />
    </div>
  </Tooltip>
)

const templateColumns = 'auto 55px 55px 60px 30px'

const useStyles = makeStyles(theme => ({
  contactsCard: {
    height: 350,
    minWidth: 200
  },
  contactRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 10,
    alignItems: 'center'
  },
  contactInfo: {
    minWidth: 0,
    marginLeft: 10
  },
  header: {
    display: 'grid',
    gridTemplateColumns: templateColumns,
    paddingTop: 5,
    alignItems: 'center',
    fontSize: '12px'
  },
  headerItem: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center'
    // wordSpacing: 100
  },
  dataItem: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '15px',
    alignItems: 'center',
    marginRight: '12px'
  },
  nameParagraph: {
    fontSize: 16,
    minWidth: 450,
    fontWeight: 600,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  titleParagraph: {
    fontSize: 12,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  divider: {
    backgroundColor: '#C6ADF1'
  }
}))

interface ContactRowProps {
  contact: Contact
}

const allTab = (total: number, isLoading: boolean, selected: boolean) => (
  <div key='All' style={{ display: 'flex', alignItems: 'center', borderBottom: selected ? `2px solid ${theme.palette.primary.main}` : undefined, paddingBottom: 5 }}>
    <span>
      <Typography>All</Typography>
    </span>
    <span style={{ marginLeft: 5 }}>
      {isLoading ? <SkeletonLoader type='number' size='sm' /> : <Typography>{total}</Typography>}
    </span>
  </div>)

const prospectToContact = (total: number, isLoading: boolean, selected: boolean) => (
  <div key='PToC' style={{ display: 'flex', alignItems: 'center', borderBottom: selected ? `2px solid ${theme.palette.primary.main}` : undefined, paddingBottom: 5 }}>
    <span>
      {prospectToContactIcon}
    </span>
    <span style={{ marginLeft: 5 }}>
      {isLoading ? <SkeletonLoader type='number' size='sm' /> : <Typography>{total}</Typography>}
    </span>
  </div>)

const prospectTab = (total: number, isLoading: boolean, selected: boolean) => (
  <div key='Prospect' style={{ display: 'flex', alignItems: 'center', borderBottom: selected ? `2px solid ${theme.palette.primary.main}` : undefined, paddingBottom: 5 }}>
    <span>
      {fromProspectIcon}
    </span>
    <span style={{ marginLeft: 5 }}>
      {isLoading ? <SkeletonLoader type='number' size='sm' /> : <Typography>{total}</Typography>}
    </span>
  </div>)

const organicTab = (total: number, isLoading: boolean, selected: boolean) => (
  <div key='Organic' style={{ display: 'flex', alignItems: 'center', borderBottom: selected ? `2px solid ${theme.palette.primary.main}` : undefined, paddingBottom: 5 }}>
    <span>{organicIcon}</span><span style={{ marginLeft: 5 }}>{isLoading ? <SkeletonLoader type='number' size='sm' /> : <Typography>{total}</Typography>}</span>
  </div>)

export const sourceIconMapping = {
  prospect: fromProspectIcon,
  organic: organicIcon,
  iql: iqlIcon,
  prostocon: prospectToContactIcon,
  form: organicIcon
}

function ContactRow ({ contact }: ContactRowProps) {
  const [integrationModal, setIntegrationModal] = useState<number | null>(null)
  const classes = useStyles()
  const visits = contact.visits
  const chats = contact.chats
  const meetings = contact.meetings
  const contactName = contact.firstName + ' ' + contact.lastName
  const initials = getInitials(contactName)
  const contactLink = `/contacts/${contact.id}`
  const prospectIcon = 'contactType' in contact ? sourceIconMapping[contact.sourceIcon] : <></>

  return (
    <>
      {integrationModal &&
        <IntegrationModal
          contactID={integrationModal}
          onClose={() => setIntegrationModal(null)}
        />}
      <Link to={contactLink} target='_blank'>
        <div className={classes.contactRow}>
          <Avatar
            participantID={contact.id as number}
            initials={initials}
            size='md'
            avatarUrl={contact?.avatarUrl || ''}
          />
          <div className={classes.contactInfo}>
            <div style={{ display: 'flex' }}>
              <div className={classes.nameParagraph}>
                {contactName}
              </div>
            </div>
            {Boolean(contact.title) && (
              <div className={classes.titleParagraph}>{contact.title}</div>
            )}
          </div>
          <div style={{ paddingRight: 10 }}>
            {prospectIcon}
          </div>
          <div style={{ cursor: 'pointer' }} onClick={() => setIntegrationModal(contact.id as number)}>
            <Icon icon='addIntegration' />
          </div>
        </div>
      </Link>
      <div style={{ display: 'flex', marginLeft: '50px', marginBottom: '10px' }}>
        <div className={classes.dataItem}>
          <Tooltip title='Visits'>
            <div style={{ marginRight: '3px' }}>
              <Icon icon='visitCursor' size='sm' />
            </div>
          </Tooltip>
          <div>
            {visits || 0}
          </div>
        </div>
        <div className={classes.dataItem}>
          <Tooltip title='Chats'>
            <div style={{ marginRight: '3px' }}>
              <Icon icon='chat' size='sm' />
            </div>
          </Tooltip>
          <div>
            {chats || 0}
          </div>
        </div>
        <div className={classes.dataItem}>
          <Tooltip title='Meetings Booked'>
            <div style={{ marginRight: '3px' }}>
              <Icon icon='calendar' size='sm' />
            </div>
          </Tooltip>
          <div>
            {meetings || 0}
          </div>
        </div>
      </div>
      <Divider className={classes.divider} />
    </>
  )
}

type ContactTab = 'All' | 'Organic' | 'Prospect' | 'PToC'

function ContactsContents () {
  const [selectedTab, setSelectedTab] = useState<ContactTab>('All')
  const { account, propspectModal, setProspectModal } = useContext(AccountPageContext)
  const acf = accountContactsFilter(account.domain)

  const { data: { list: PContacts, meta: PMeta }, isLoading: PLoading, error: PError } = Contact.loadAll({ searchParams: { filters: [contactTypeFilter('prospect').concat(acf)] } })
  const { data: { list: OContacts, meta: OMeta }, isLoading: OLoading, error: OError } = Contact.loadAll({ searchParams: { filters: [contactTypeFilter('organic').concat(acf)] } })
  const { data: { list: PTOContacts, meta: PTOMeta }, isLoading: PTOLoading, error: PTOError } = Contact.loadAll({ searchParams: { filters: [contactTypeFilter('prostoorg').concat(acf)] } })
  const { data: { list: contacts, meta }, isLoading, error } = Contact.loadAll({ searchParams: { filters: [acf] } })

  const currentContacts = selectedTab === 'All' ? contacts
    : selectedTab === 'Prospect' ? PContacts
      : selectedTab === 'PToC' ? PTOContacts
        : OContacts

  const currentError = selectedTab === 'All' ? error
    : selectedTab === 'Prospect' ? PError
      : selectedTab === 'PToC' ? PTOError
        : OError

  const currentIsLoading = selectedTab === 'All' ? isLoading
    : selectedTab === 'Prospect' ? PLoading
      : selectedTab === 'PToC' ? PTOLoading
        : OLoading

  if (currentError) {
    console.log({ currentError })
    return (
      <div>
        There was problem loading contacts for this account
      </div>
    )
  }

  const tabs = [
    allTab(meta?.total, isLoading, selectedTab === 'All'),
    organicTab(OMeta?.total, OLoading, selectedTab === 'Organic'),
    prospectTab(PMeta?.total, PLoading, selectedTab === 'Prospect'),
    prospectToContact(PTOMeta?.total, PTOLoading, selectedTab === 'PToC')
  ]

  return (
    <div>
      {propspectModal && (
        <ProspectModal
          onHide={() => setProspectModal(null)}
        />)}
      <AccountContactTabs
        tabs={tabs}
        setSelectedTab={setSelectedTab}
        selectedTab={selectedTab}
      />
      <ScrollBox
        style={{ maxHeight: 300 }}
        noScrollBar
      >
        {currentIsLoading ? (
          <SkeletonTableLoader
            rowCount={3}
            types={['avatarNameIcon&Subtitle', 'number', 'number', 'number']}
            columns={templateColumns}
            justifyCenter
          />) : contacts.length === 0 ? (
            <EmptyBox
              message='No contacts on this account'
            />) : (
          currentContacts.map((contact, index) => (
            <ContactRow
              contact={contact}
              key={index}
            />)
          ))}
      </ScrollBox>
    </div>
  )
}

function AccountContactTabs ({ tabs, selectedTab, setSelectedTab }: { tabs: any[], selectedTab: ContactTab, setSelectedTab: Dispatch<SetStateAction<ContactTab>> }) {
  return (
    <Tabs
      value={selectedTab}
      setValue={(e: { key: ContactTab }) => setSelectedTab(e.key)}
      tabs={tabs}
      variant='standard'
      noIndicator
    />
  )
}

function AccountContacts () {
  const classes = useStyles()
  const { account, setProspectModal } = useContext(AccountPageContext)
  const { planName } = useContext(ShareContext)
  const isSnapshot = planName() === SNAPSHOT_PLAN_NAME
  const [upgradePrompt, setUpgradePrompt] = useState(false)
  const titleAction = (
    <Label
      label='Find more contacts'
      startIcon='userSearch'
      color='primary'
      variant='body2'
      onClick={isSnapshot ? () => setUpgradePrompt(true) : () => setProspectModal(account)}
    />
  )

  const linkedinLink = account.linkedIn
    ? (
      <Tooltip style={{ marginLeft: 10 }} title={'View more people from ' + account.name + ' on Linkedin'}>
        <IconButton color='primary' size='small' onClick={() => window.open(account.linkedIn + '/people', '_blank')}>
          <Icon icon='linkedin' color='#0072B1' />
        </IconButton>
      </Tooltip>
    ) : undefined

  if (!account) {
    return (
      <DashboardCard
        title='Contacts'
        noScrollbox
      >
        <div className={classes.contactsCard}>
          <EmptyBox
            message='No account found for this domain'
          />
        </div>
      </DashboardCard>
    )
  }

  return (
    <>
      <DashboardCard
        title={<div style={{ display: 'flex', alignItems: 'center' }}>Account Contacts {linkedinLink}</div>}
        noScrollbox
        titleAction={titleAction}
      >
        <div className={classes.contactsCard}>
          <ContactsContents />
        </div>
      </DashboardCard>
      {upgradePrompt &&
        <SnapshotUpgradePromptModal
          open={upgradePrompt}
          onClose={() => setUpgradePrompt(false)}
        >
          {contactUpgradeMessage}
        </SnapshotUpgradePromptModal>}
    </>
  )
}

export default AccountContacts
