import { Chip, Tooltip } from '@material-ui/core'
import AddCircleOutlineOutlined from '@material-ui/icons/AddCircleOutlineOutlined';
import Block from '@material-ui/icons/Block';
import Cancel from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';
import { useState } from 'react';
import { formatIntWithCommas } from 'library/helpers';

interface FilterChipProps {
  key: string | number
  value: string
  excludable?: boolean
  excluded?: boolean
  operator?: string
  removeValue: () => void
  changeValue: () => void
}

export default function FilterChip ({ key, value, excludable, excluded, operator, removeValue, changeValue }: FilterChipProps): JSX.Element {
  const [showSwitchButton, setShowSwitchButton] = useState(false)
  let label = ''
  let tooltip = ''
  if (value.length > 30) {
    tooltip = value
    label = value.slice(0, 30) + '...'
  } else {
    label = value
  }
  if (excluded) label = `- ${value}`
  if (operator) label = `${operator} ${formatIntWithCommas(value)}`

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '10px' }}>
      <Tooltip title={tooltip} placement='top'>
        <div
          key={key}
          style={{ position: 'relative', margin: '5px' }}
          onMouseEnter={() => setShowSwitchButton(true)}
          onMouseLeave={() => setShowSwitchButton(false)}
        >
          <Chip
            label={label}
            onDelete={(event) => {
              event.stopPropagation()
              removeValue()
            }}
            onClick={(event) => {
              event.stopPropagation()
            }}
            style={{
              backgroundColor: excluded ? '#FDEAE9' : '#DCDCDC',
              color: excluded ? '#EA422A' : '#343434'
            }}
            deleteIcon={<Cancel style={{ color: '#808080' }} />}
          />
          {excludable ? (
            <Tooltip title={excluded ? 'Include' : 'Exclude'}>
              <IconButton
                style={{
                  position: 'absolute',
                  top: '50%',
                  right: '30px',
                  transform: 'translateY(-50%)',
                  height: '0.5rem',
                  width: '0.5rem',
                  opacity: showSwitchButton ? 1 : 0,
                  backgroundColor: '#DCDCDC',
                  color: '#343434',
                  transition: 'opacity 0.3s'
                }}
                onClick={(event) => {
                  event.stopPropagation()
                  changeValue()
                }}
              >
                {excluded ? <AddCircleOutlineOutlined fontSize='small' /> : <Block fontSize='small' />}
              </IconButton>
            </Tooltip>
          ) : <></>}
        </div>
      </Tooltip>
    </div>
  )
}
