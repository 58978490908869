import { forwardRef, useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import ListSubheader from '@material-ui/core/ListSubheader'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Collapse from '@material-ui/core/Collapse'
import AccessControl from 'components/AccessControl'
import { ReactComponent as ChatSettingsSelected } from 'img/ChatSettingsSelected.svg'
import { ReactComponent as ChatSettingsUnselected } from 'img/ChatSettingsUnselected.svg'
import { ReactComponent as MySettingsSelected } from 'img/MySettingsSelected.svg'
import { ReactComponent as MySettingsUnselected } from 'img/MySettingsUnselected.svg'
import { ReactComponent as OrgSettingsSelected } from 'img/OrgSettingsSelected.svg'
import { ReactComponent as OrgSettingsUnselected } from 'img/OrgSettingsUnselected.svg'
import { ReactComponent as AdminSettingsSelected } from 'img/AdminSettingsSelected.svg'
import { ReactComponent as AdminSettingsUnselected } from 'img/AdminSettingsUnselected.svg'
import { Icon, Tooltip } from 'library/materialUI'
import { FAQ_FLAG, ShareContext } from 'share-context'
import { components, SessionContext } from 'session-context'
import { INSIGHTS_PLAN_NAME, SNAPSHOT_PLAN_NAME } from 'library/constants'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    minWidth: 230,
    height: '100vh',
    overflow: 'auto',
    fontFamily: 'Poppins, sans serif'
  },
  nested: {
    paddingLeft: theme.spacing(5)
  },
  itemHeader: {
    display: 'flex',
    alignItems: 'center'
  },
  listItemSelected: {
    fontSize: '15px',
    color: '#2A2A2A',
    fontWeight: 600
  },
  listItemUnselected: {
    fontSize: '15px',
    color: '#7A7A7A'
  },
  iconStyle: {
    borderRadius: '20px',
    height: '20px',
    width: '20px',
    backgroundColor: '#9933FF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '10px'
  }
}))

const MySettingsLink = forwardRef((props, ref) => (
  <Link innerRef={ref} to='/settings/my_settings/profile' {...props} />
))

const EmailManagementLink = forwardRef((props, ref) => (
  <Link innerRef={ref} to='/settings/email_management/spam_domains' {...props} />
))

const SavedRepliesLink = forwardRef((props, ref) => (
  <Link innerRef={ref} to='/settings/saved_replies' {...props} />
))

const ConversationTagsLink = forwardRef((props, ref) => (
  <Link innerRef={ref} to='/settings/conversation_tags' {...props} />
))

const CompanyProfileLink = forwardRef((props, ref) => (
  <Link innerRef={ref} to='/settings/company_profile' {...props} />
))

const ChatSettingsLink = forwardRef((props, ref) => (
  <Link innerRef={ref} to='/settings/chat_settings/chat_availability' {...props} />
))

const DomainSettingsLink = forwardRef((props, ref) => (
  <Link innerRef={ref} to='/settings/chat_settings/domains' {...props} />
))

const InstallLink = forwardRef((props, ref) => (
  <Link innerRef={ref} to='/settings/installation/manual' {...props} />
))

const MeetingTypesLink = forwardRef((props, ref) => (
  <Link innerRef={ref} to='/settings/meeting_types' {...props} />
))

const EmailSettingsLink = forwardRef((props, ref) => (
  <Link innerRef={ref} to='/settings/email_settings' {...props} />
))

const KeyWordGroupsLink = forwardRef((props, ref) => (
  <Link innerRef={ref} to='/settings/keyword_groups' {...props} />
))

const FAQLink = forwardRef((props, ref) => (
  <Link innerRef={ref} to='/settings/faq_management/faqs' {...props} />
))

const BillingLink = forwardRef((props, ref) => (
  <Link innerRef={ref} to='/settings/subscriptions/plan' {...props} />
))

const IntegrationsLink = forwardRef((props, ref) => (
  <Link innerRef={ref} to='/settings/integrations' {...props} />
))

const SecurityLink = forwardRef((props, ref) => (
  <Link innerRef={ref} to='/settings/security' {...props} />
))

const DataPrivacyLink = forwardRef((props, ref) => (
  <Link innerRef={ref} to='/settings/data_privacy' {...props} />
))
const HighIntentPageLink = forwardRef((props, ref) => (
  <Link innerRef={ref} to='/settings/high_intent_pages' {...props} />
))
const SignalsScoreLink = forwardRef((props, ref) => (
  <Link innerRef={ref} to='/settings/signals_score' {...props} />
))
const BlockedPagesLink = forwardRef((props, ref) => (
  <Link innerRef={ref} to='/settings/blocked_pages' {...props} />
))
const GlobalRoutingLink = forwardRef((props, ref) => (
  <Link innerRef={ref} to='/settings/global_routing' {...props} />
))
const CustomSoundsPageLink = forwardRef((props, ref) => (
  <Link innerRef={ref} to='/settings/custom_sounds' {...props} />
))
const AutoMQLLink = forwardRef((props, ref) => {
  return <Link innerRef={ref} to='/settings/mql' {...props} />
})
const AIContentLink = forwardRef((props, ref) => (
  <Link innerRef={ref} to='/settings/ai_content' {...props} />
))

function ChatSettingsNavBar ({ location }) {
  const classes = useStyles()
  let page = 'settings'
  if (location && location.pathname.split('/').length > 2) {
    page = location.pathname.split('/')[2]
  }
  const [mySettingsOpen, setMySettingsOpen] = useState(['my_settings', 'saved_replies', 'company_profile'].includes(page))
  const [chatSettingsOpen, setChatSettingsOpen] = useState(['chat_settings', 'keyword_groups', 'conversation_tags', 'email_management', 'faq_management'].includes(page))
  const [organizationSettingsOpen, setOrganizationSettingsOpen] = useState(['teams', 'meeting_types', 'email_settings', 'integrations', 'installation', 'high_intent_pages', 'blocked_pages', 'global_routing', 'mql', 'ai_content'].includes(page))
  const [adminOpen, setAdminOpen] = useState(['subscriptions', 'security'].includes(page))
  const { flags, planName } = useContext(ShareContext)
  const { isOn } = useContext(SessionContext)
  const hasScoringComponent = isOn(components.SCORE_INSIGHTS)
  const isSnapshot = planName() === SNAPSHOT_PLAN_NAME
  const isInsights = planName() === INSIGHTS_PLAN_NAME

  function handleClickmySettingsOpen () {
    setMySettingsOpen(!mySettingsOpen)
  }

  function handleClickChatSettingsOpen () {
    setChatSettingsOpen(!chatSettingsOpen)
  }

  function handleOrganizationSettingsOpen () {
    setOrganizationSettingsOpen(!organizationSettingsOpen)
  }

  function handleAdminOpen () {
    setAdminOpen(!adminOpen)
  }
  const orgSubPages = ['meeting_types', 'integrations', 'installation', 'email_settings', 'high_intent_pages', 'blocked_pages', 'global_routing', 'mql']
  if (!isOn(components.CHAT)) {
    orgSubPages.push('domain_settings')
  }

  return (
    <div>
      <List
        component='nav'
        aria-labelledby='nested-list-subheader'
        disablePadding
        subheader={
          <ListSubheader component='div' id='nested-list-subheader' disableSticky>
            Settings
          </ListSubheader>
        }
        className={classes.root}
      >
        {/* TODO: FIND A CLEANER SOLUTION FOR THIS:
            Currently, if the App settings is empty dropdown will NOT dissapear by itself,

            I found a way around it, but it's not the best.

            What currently is being done:
              - I'm asking for 'add_teams' permissions on the whole list.
              - Why? Because none of the roles that have App Settings empty have Teams permissions.

            What needs to be done:
              - Write some logic so the App settings ListItem doesn't show up when empty.
              - Currently it checks for Team Permissions, which is not the best solution.
              - I do so in the line immediately below */}

        <ListItem button onClick={handleClickmySettingsOpen}>
          <div className={classes.itemHeader}>
            {['my_settings', 'saved_replies', 'company_profile'].includes(page)
              ? <MySettingsSelected style={{ marginRight: 15 }} />
              : <MySettingsUnselected style={{ marginRight: 15 }} />}
            My Settings
          </div>
        </ListItem>
        <Collapse in={mySettingsOpen} timeout='auto' unmountOnExit>
          <List component='div' disablePadding>
            <ListItem
              button
              component={MySettingsLink}
              className={classes.nested}
            >
              <div className={page === 'my_settings' ? classes.listItemSelected : classes.listItemUnselected}>My Settings</div>
            </ListItem>
            <AccessControl requiredComponent={components.CHAT}>
              <ListItem
                button
                component={SavedRepliesLink}
                className={classes.nested}
              >
                <div className={page === 'saved_replies' ? classes.listItemSelected : classes.listItemUnselected}>Saved Replies</div>
              </ListItem>
            </AccessControl>
            <AccessControl requiredPermission='semi_admin'>
              <ListItem
                button
                component={CompanyProfileLink}
                className={classes.nested}
              >
                <div className={page === 'company_profile' ? classes.listItemSelected : classes.listItemUnselected}>Company Profile</div>
              </ListItem>
            </AccessControl>
          </List>
        </Collapse>
        {isOn(components.CHAT) && (
          <AccessControl requiredPermission='semi_admin'>
            <ListItem button onClick={handleClickChatSettingsOpen}>
              <div className={classes.itemHeader}>
                {['chat_settings', 'keyword_groups', 'conversation_tags', 'email_management', 'faq_management'].includes(page)
                  ? <ChatSettingsSelected style={{ marginRight: 15 }} />
                  : <ChatSettingsUnselected style={{ marginRight: 15 }} />}
                Chat Settings
              </div>
            </ListItem>
            <Collapse in={chatSettingsOpen} timeout='auto' unmountOnExit>
              <List component='div' disablePadding>
                <AccessControl requiredPermission='semi_admin'>
                  <ListItem
                    button
                    component={ChatSettingsLink}
                    className={classes.nested}
                  >
                    <div className={page === 'chat_settings' ? classes.listItemSelected : classes.listItemUnselected}>Chat Settings</div>
                  </ListItem>
                </AccessControl>
                <AccessControl requiredPermission='semi_admin' requiredComponent={components.CHAT}>
                  <ListItem
                    button
                    component={KeyWordGroupsLink}
                    className={classes.nested}
                  >
                    <div className={page === 'keyword_groups' ? classes.listItemSelected : classes.listItemUnselected}>Keyword Groups</div>
                  </ListItem>
                </AccessControl>
                <AccessControl requiredPermission='manager' requiredComponent={components.CHAT}>
                  <ListItem
                    button
                    component={ConversationTagsLink}
                    className={classes.nested}
                  >
                    <div className={page === 'conversation_tags' ? classes.listItemSelected : classes.listItemUnselected}>Conversation Tags</div>
                  </ListItem>
                </AccessControl>
                <AccessControl requiredPermission='semi_admin' requiredComponent={components.EMAILS}>
                  <ListItem
                    button
                    component={EmailManagementLink}
                    className={classes.nested}
                  >
                    <div className={page === 'email_management' ? classes.listItemSelected : classes.listItemUnselected}>Email Capture Settings</div>
                  </ListItem>
                </AccessControl>
                <AccessControl flag={flags[FAQ_FLAG]} requiredPermission='semi_admin' requiredComponent={components.PLAYMAKER}>
                  <ListItem
                    button
                    component={FAQLink}
                    className={classes.nested}
                  >
                    <div className={page === 'faq_management' ? classes.listItemSelected : classes.listItemUnselected}>FAQ Management</div>
                  </ListItem>
                </AccessControl>
                {flags['ai-chatbot'] && (
                  <AccessControl requiredPermission='semi_admin'>
                    <ListItem
                      button
                      component={AIContentLink}
                      className={classes.nested}
                    >
                      <div style={{ display: 'flex', flexDirection: 'row' }} className={page === 'ai_content' ? classes.listItemSelected : classes.listItemUnselected}>
                        AI Content
                        {(isSnapshot || isInsights) && (
                          <div className={classes.iconStyle}><Icon icon='upArrow' size='sm' color='white' /></div>
                        )}
                      </div>
                    </ListItem>
                  </AccessControl>
                )}
              </List>
            </Collapse>
          </AccessControl>
        )}
        <ListItem button onClick={handleOrganizationSettingsOpen}>
          <div className={classes.itemHeader}>
            {orgSubPages.includes(page)
              ? <OrgSettingsSelected style={{ marginRight: 15 }} />
              : <OrgSettingsUnselected style={{ marginRight: 15 }} />}
            Org Settings
          </div>
        </ListItem>
        <Collapse in={organizationSettingsOpen} timeout='auto' unmountOnExit>
          <List component='div' disablePadding>
            {!isOn(components.CHAT) && (
              <AccessControl requiredPermission='semi_admin'>
                <ListItem
                  button
                  component={DomainSettingsLink}
                  className={classes.nested}
                >
                  <div className={page === 'chat_settings' ? classes.listItemSelected : classes.listItemUnselected}>Domains</div>
                </ListItem>
              </AccessControl>
            )}
            <AccessControl requiredComponent={components.CALENDAR}>
              <ListItem
                button
                component={MeetingTypesLink}
                className={classes.nested}
              >
                <div className={page === 'meeting_types' ? classes.listItemSelected : classes.listItemUnselected}>Meetings</div>
              </ListItem>
            </AccessControl>
            <AccessControl requiredPermission='semi_admin' requiredComponent={components.EMAILS}>
              <ListItem
                button
                component={EmailSettingsLink}
                className={classes.nested}
              >
                <div className={page === 'email_settings' ? classes.listItemSelected : classes.listItemUnselected}>Email Settings</div>
              </ListItem>
            </AccessControl>
            <AccessControl requiredPermission='semi_admin'>
              <ListItem
                button
                component={IntegrationsLink}
                className={classes.nested}
              >
                <div className={page === 'integrations' ? classes.listItemSelected : classes.listItemUnselected}>Integrations</div>
              </ListItem>
            </AccessControl>
            <AccessControl requiredPermission='semi_admin'>
              <ListItem
                button
                component={InstallLink}
                className={classes.nested}
              >
                <div className={page === 'installation' ? classes.listItemSelected : classes.listItemUnselected}>Installation</div>
              </ListItem>
            </AccessControl>
            <AccessControl requiredPermission='semi_admin'>
              <ListItem
                button
                component={HighIntentPageLink}
                className={classes.nested}
              >
                <div style={{ display: 'flex', flexDirection: 'row' }} className={page === 'high_intent_pages' ? classes.listItemSelected : classes.listItemUnselected}>
                  High-Intent Pages
                  {isSnapshot && (
                    <div className={classes.iconStyle}><Icon icon='upArrow' size='sm' color='white' /></div>
                  )}
                </div>
              </ListItem>
            </AccessControl>
            <AccessControl requiredPermission='semi_admin' requiredComponent={components.CHAT}>
              <ListItem
                button
                component={BlockedPagesLink}
                className={classes.nested}
              >
                <div className={page === 'blocked_pages' ? classes.listItemSelected : classes.listItemUnselected}>Blocked Pages</div>
              </ListItem>
            </AccessControl>
            {!isSnapshot && (
              <AccessControl requiredPermission='semi_admin'>
                <ListItem
                  button
                  component={CustomSoundsPageLink}
                  className={classes.nested}
                >
                  <div className={page === 'custom_sounds' ? classes.listItemSelected : classes.listItemUnselected}>Live View Map Sounds</div>
                </ListItem>
              </AccessControl>)}
            {flags['signals-score-v2'] && (
              <AccessControl requiredPermission='semi_admin'>
                <ListItem
                  button
                  component={SignalsScoreLink}
                  className={classes.nested}
                >
                  <div style={{ display: 'flex', flexDirection: 'row' }} className={page === 'signals_score' ? classes.listItemSelected : classes.listItemUnselected}>
                    Signals Score {!hasScoringComponent ? (
                      <div className={classes.iconStyle}><Icon icon='upArrow' size='sm' color='white' /></div>
                    ) : (
                      <Tooltip title='Powered by Artificial Intelligence'>
                        <div>
                          <Icon icon='aiSparkles' />
                        </div>
                      </Tooltip>
                    )}
                  </div>
                </ListItem>
              </AccessControl>
            )}
            {flags['global-routing'] && (
              <AccessControl requiredPermission='semi_admin' requiredComponent={components.GLOBAL_ROUTING}>
                <ListItem
                  button
                  component={GlobalRoutingLink}
                  className={classes.nested}
                >
                  <div className={page === 'global_routing' ? classes.listItemSelected : classes.listItemUnselected}>Global Routing</div>
                </ListItem>
              </AccessControl>
            )}
            {flags['auto-mql'] && (
              <AccessControl requiredPermission='semi_admin' requiredComponent={components.EMAIL_QUERY}>
                <ListItem
                  button
                  component={AutoMQLLink}
                  className={classes.nested}
                >
                  <div className={page === 'mql' ? classes.listItemSelected : classes.listItemUnselected}>Email Query String</div>
                </ListItem>
              </AccessControl>)}
          </List>
        </Collapse>
        <AccessControl requiredPermission='strict_admin'>
          <ListItem button onClick={handleAdminOpen}>
            <div className={classes.itemHeader}>
              {['subscriptions', 'security', 'audit_logs'].includes(page)
                ? <AdminSettingsSelected style={{ marginRight: 15 }} />
                : <AdminSettingsUnselected style={{ marginRight: 15 }} />}
              Admin Settings
            </div>
          </ListItem>
        </AccessControl>
        <Collapse in={adminOpen} timeout='auto' unmountOnExit>
          <List component='div' disablePadding>
            <AccessControl requiredPermission='semi_admin'>
              <ListItem
                button
                component={BillingLink}
                className={classes.nested}
              >
                <div className={page === 'subscriptions' ? classes.listItemSelected : classes.listItemUnselected}>Billing</div>
              </ListItem>
            </AccessControl>
            <AccessControl requiredPermission='strict_admin'>
              <ListItem
                button
                component={SecurityLink}
                className={classes.nested}
              >
                <div className={page === 'security' ? classes.listItemSelected : classes.listItemUnselected}>Security</div>
              </ListItem>
            </AccessControl>
            <AccessControl requiredComponent={components.CHAT}>
              <ListItem
                button
                component={DataPrivacyLink}
                className={classes.nested}
              >
                <div className={page === 'data_privacy' ? classes.listItemSelected : classes.listItemUnselected}>Data Privacy</div>
              </ListItem>
            </AccessControl>
          </List>
        </Collapse>
      </List>
    </div>
  )
}

export default ChatSettingsNavBar
