import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import Link from '@material-ui/core/Link'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import Snackbar from '@material-ui/core/Snackbar'
import { parse } from 'query-string'
import Card from '@material-ui/core/Card'
import { makeStyles } from '@material-ui/core/styles'
import { Icon } from 'library/materialUI'
import Typography from '@material-ui/core/Typography'
import { getCredentialsStatus } from 'api/integrations'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center'
  },
  card: {
    width: 350,
    height: 375,
    marginTop: 200,
    borderRadius: 10
  },
  logoContainer: {
    alignItems: 'left'
  },
  logo: {
    height: 50
  },
  logoWrapper: {
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 25
  },
  button: {
    position: 'absolute',
    bottom: 40,
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%'
  },
  cardContent: {
    height: '100%',
    position: 'relative',
    margin: 20
  },
  description: {
    textAlign: 'left',
    fontSize: 15,
    marginTop: 25
  }
}))

const oauth_mappings = {
  access_denied: 'Oauth Error: access was denied. Please try again.',
  immediate_unsuccessful: 'Oauth Error: please try again.'
}

function oauth_message (input_message) {
  const attempt = oauth_mappings[input_message]
  if (!input_message) {
    return ''
  }
  if (!attempt) {
    return 'Unknown error. Please try again'
  }
  return attempt
}
const ImgButton = (props) => {
  const [pressed, setPressed] = useState(false)
  function performOauth () {
    setPressed(true)
    window.location.href = props.oauthUrl
  }
  return (
    <div style={{ cursor: 'pointer' }} className={props.className}>
      {pressed ? (
        <img src={props.pressedBtn} alt='Click Here' style={{ height: 50 }} />
      ) : (
        <Link onClick={performOauth}>
          <img src={props.btn} alt='Click Here' style={{ height: 50 }} />
        </Link>
      )}
    </div>
  )
}

const OauthIntegrationPage = (props) => {
  const classes = useStyles()
  const [hasConnection, setHasConnection] = useState(false)
  const [oauthUrl, setOAuthUrl] = useState()
  const queryString = parse(window.location.search)
  const oauthError = oauth_message(queryString.error)
  const [oauthMessage, setOAuthMessage] = useState(oauthError)

  useEffect(() => {
    getCredentialsStatus(props.integration)
      .then((response) => {
        setOAuthUrl(response.data.attributes.oauth_start_url)
        setHasConnection(response.data.attributes.has_credentials)
      })
  }, [props.integration])

  function performOauth () {
    window.location.href = oauthUrl
  }

  function onMessageClose () {
    setOAuthMessage(false)
  }

  return (
    <div className={classes.container}>
      <Card className={classes.card} raised>
        <div className={classes.cardContent}>
          <div className={classes.logoContainer}>
            <div className={classes.logoWrapper}>
              <div className={classes.logo} style={{ marginRight: '10px' }}>
                <Icon icon='logo' size='lg' />
              </div>
              <img src={props.icon} className={classes.logo} alt='Logo' />
            </div>
          </div>
          <div>{props.name}</div>
          <div>
            {hasConnection ? (
              <Chip label={props.name + ' connection active'} />
            ) : (
              <div>
                <Typography variant='body2' className={classes.description}>
                  {props.description}
                </Typography>
                {props.oauthBtn ? (
                  <ImgButton
                    className={classes.button}
                    oauthUrl={oauthUrl}
                    btn={props.oauthBtn}
                    pressedBtn={props.oauthPressedBtn}
                  />
                ) : (
                  <Button
                    variant='outlined'
                    color='primary'
                    className={classes.button}
                    onClick={performOauth}
                  >
                    Start activation
                  </Button>
                )}
              </div>
            )}
          </div>
        </div>
      </Card>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        aria-describedby='client-snackbar'
        action={[
          <IconButton
            key='close'
            aria-label='Close'
            color='inherit'
            onClick={onMessageClose}
          >
            <CloseIcon className={classes.icon} />
          </IconButton>
        ]}
        open={oauthMessage}
        autoHideDuration={6000}
        ContentProps={{
          'aria-describedby': 'message-id'
        }}
        message={<span id='message-id'>{oauthError}</span>}
      />
    </div>
  )
}

export default OauthIntegrationPage
