import { Typography } from 'library/materialUI'
import { Select } from 'cf-components/material-wrappers/MaterialComponents';
import { useEffect, useState } from 'react'

type lowAndHighArray = [number, number]

const employeeOptions = [
  { label: '1', value: 1 },
  { label: '10', value: 10 },
  { label: '20', value: 20 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
  { label: '250', value: 250 },
  { label: '500', value: 500 },
  { label: '1,000', value: 1000 },
  { label: '1,500', value: 1500 },
  { label: '2,000', value: 2000 },
  { label: '2,500', value: 2500 },
  { label: '5,000', value: 5000 },
  { label: '10,000+', value: 10000 }
]

interface SliderSelectorProps {
  currentSelection: [string, string] | [string]
  setSelection: (array: lowAndHighArray) => void
  noLabels?: boolean
  allowZero?: boolean
}

export default function SliderSelector ({ currentSelection, setSelection, noLabels, allowZero } : SliderSelectorProps) {
  const startingValue: lowAndHighArray = currentSelection.length === 2 ? [Number(currentSelection[0]), Number(currentSelection[1])] : (allowZero ? [0, 0] : [500, 5000])
  const [employeeLabel, setEmployeeLabel] = useState(`${startingValue[0]} - ${startingValue[1]}${startingValue[1] === 10000 ? '+' : ''}`)
  const [value, setValue] = useState(startingValue)

  const handleChange = (newValue: number, index: number) => {
    if (newValue === 0) {
      newValue += 1
    }
    if (index === 0) {
      setValue([newValue, value[1]])
    } else {
      setValue([value[0], newValue])
    }
  }

  useEffect(() => {
    if (value[0] && value[1]) {
      setSelection(value)
    }
    setEmployeeLabel(`${value[0]} - ${value[1]}${value[1] === 10000 ? '+' : ''}`)
    // eslint-disable-next-line
  }, [value])

  useEffect(() => {
    if (!currentSelection.length && allowZero) setValue([0, 0])
    // eslint-disable-next-line
  }, [currentSelection])

  return (
    <div>
      {!noLabels && employeeLabel}
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Select
          freeSolo
          inputType='number'
          onChange={(e: number | string) => handleChange(Number(e), 0)}
          onBlur={(e: any) => handleChange(Number(e.target.value), 0)}
          value={String(value[0])}
          options={employeeOptions}
          disableClear
          max={10000}
          min={1}
          key='employee1'
          filterOptions={false}
          width='100%'
        />
        <Typography style={{ paddingLeft: 10, paddingRight: 10 }}>to</Typography>
        <Select
          freeSolo
          inputType='number'
          onChange={(e: number | string) => handleChange(Number(e), 1)}
          onBlur={(e: any) => handleChange(Number(e.target.value), 1)}
          value={String(value[1])}
          options={employeeOptions}
          disableClear
          max={10000}
          min={1}
          key='employee2'
          filterOptions={false}
          width='100%'
        />
      </div>
    </div>
  )
}
