import { useState, useContext, useEffect, useRef } from 'react'
import { SessionContext } from 'session-context'
import { getSettings } from 'api/settings'
import BotForm from './BotForm'
import { Link } from 'react-router-dom'
import { saveBot } from '../saveBot'
import FlowBotPage from './FlowBotPage'
import RoutingBotPage from './RoutingBotPage'
import ConversationalContentPage from './ConversationalContentPage'
import { getInitialValues, initializeBotFromID, publishBot } from './botConfig'
import { getBotParticipant } from 'api/bots'
import { getContentPagesByBotId } from 'api/content_pages'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import Button from '@material-ui/core/Button'
import { saveTrainingData } from 'api/ai_settings'

function Alert (props) {
  return <MuiAlert elevation={6} variant='outlined' {...props} style={{ backgroundColor: 'white' }} />
}

function MaxBotsWarning (props) {
  const maxActiveBotsWarnings = props.maxActiveBotsWarnings
  const setMaxActiveBotsWarnings = props.setMaxActiveBotsWarnings
  return (
    <Snackbar open={Boolean(maxActiveBotsWarnings.length)} autoHideDuration={4000} onClose={() => setMaxActiveBotsWarnings([])}>
      <Alert
        severity='warning'
        action={
          <Button
            size='small'
            variant='outlined'
            onClick={() => setMaxActiveBotsWarnings([])}
          >Got it!
          </Button>
        }
      >
        {maxActiveBotsWarnings.map(w => w.detail)}
      </Alert>
    </Snackbar>
  )
}

function BotEditor (props) {
  if (props.botType === 'flow' || props.botType === 'form') {
    return (
      <FlowBotPage
        {...props}
      />
    )
  } else if (props.botType === 'content_page') {
    return (
      <ConversationalContentPage
        {...props}
      />
    )
  } else {
    return (
      <RoutingBotPage
        {...props}
      />
    )
  }
}

function BotEditPage (props) {
  const [botID] = useState(props.botID)
  const [canSave, setCanSave] = useState(false)
  const [botType, setBotType] = useState(null)
  const [botParticipant, setBotParticipant] = useState(null)
  const [bot, setBot] = useState(null)
  const [flow, setFlow] = useState(null)
  const [flowID, setFlowID] = useState(null)
  const [subdomain, setSubdomain] = useState('')
  const [contentPagesDict, setContentPagesDict] = useState({})
  const [maxActiveBotsWarnings, setMaxActiveBotsWarnings] = useState([])
  const { user } = useContext(SessionContext)
  const flowBuilderDataRef = useRef(null)
  const chatServiceUrl = user.links.chat_service

  useEffect(() => {
    getSettings()
      .then(response => {
        setSubdomain(response.data.attributes.content_subdomain)
      })
    getBotParticipant({})
      .then(response => {
        setBotParticipant(response.data)
      })
  }, [])

  useEffect(() => {
    if (botID) {
      initializeBotFromID({ botID, chatServiceUrl, setBotType, setBot, setFlow, setFlowID })
    }
    // eslint-disable-next-line
  }, [botID, botType, chatServiceUrl])

  useEffect(() => {
    if (botID && botType === 'content_page') {
      getContentPagesByBotId({ botID })
        .then(response => {
          const contentDict = Object.assign({}, ...response.data.map((x) => ({ [x.attributes?.bot_id]: x })))
          setContentPagesDict(contentDict)
        })
    }
  }, [botID, botType])

  if (!(bot && flow && botParticipant)) {
    return <></>
  }

  const save = (values, formikProps) => {
    const triggers = values.triggers.filter((e) => {
      const notEmpty = e.filters.filter((el) => {
        return el.cmp !== ''
      })
      return notEmpty.length > 0
    })
    values.triggers = triggers
    if (values.triggers.length === 0 && values.trigger_on === 'selected_criteria' && values.kind !== 'content_page') {
      values.trigger_on = 'all_visitors'
    }
    if (values.use_triggers === false && values.triggers.length !== 0) {
      values.use_triggers = true
    }
    // flowBuilderDataRef.current may not be initialized yet if the builder hasn't been rendered yet
    const builder_data = flowBuilderDataRef.current || flow?.attributes?.flow

    saveTrainingData({
      id: values.onboarding_id || 0,
      bot_id: parseInt(botID),
      prompt: values.onboarding_prompt,
      type: 'onboarding'
    })

    saveBot({ values, formikProps, botID, flow, builder_data })
      .then(response => {
        setBot(response.data)
        setFlowID(response.data.attributes.flow_id)
      })
  }

  function publish () {
    publishBot({ flowID, bot, botID, flow, setFlowID, setFlow, setMaxActiveBotsWarnings, setBot })
  }

  const initialValues = getInitialValues({ botType, bot, flow, botParticipant, contentPagesDict })
  const activeTest = bot.attributes?.active_test_id

  return (
    <>
      <BotForm
        canSave={canSave}
        setCanSave={setCanSave}
        history={props.history}
        bot={bot}
        save={save}
        initialValues={initialValues}
      >
        <BotEditor
          botType={botType}
          flow={flow}
          flowID={flowID}
          bot={bot}
          chatServiceUrl={chatServiceUrl}
          botID={botID}
          flowBuilderDataRef={flowBuilderDataRef}
          botParticipant={botParticipant}
          canSave={canSave}
          setCanSave={setCanSave}
          setFlow={setFlow}
          setFlowID={setFlowID}
          setBot={setBot}
          subdomain={subdomain}
          publishBot={publish}
          activeTest={activeTest}
          archived={Boolean(bot?.attributes.deleted_timestamp)}
        />
      </BotForm>
      <MaxBotsWarning
        maxActiveBotsWarnings={maxActiveBotsWarnings}
        setMaxActiveBotsWarnings={setMaxActiveBotsWarnings}
      />
      <Snackbar open={Boolean(activeTest)}>
        <Alert
          severity='warning' action={
            <Button
              color='primary'
              size='small'
              variant='contained'
              component={Link}
              to={`/bot_tests/dashboard/${activeTest}`}
            >View Test
            </Button>
          }
        >
          Changes cannot be saved while there is an incomplete test!
        </Alert>
      </Snackbar>
    </>
  )
}

export default BotEditPage
