import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import dateFormat from 'dateformat'
import Avatar from '@material-ui/core/Avatar'
import { getSummary, activities } from './ActivityHelpers'
import { Accordion, AccordionSummary, AccordionDetails } from 'cf-components/Accordion'
import DotIcon from '@material-ui/icons/FiberManualRecord'
import { Tooltip, Typography } from '@material-ui/core'
import { Icon } from 'library/materialUI'
import TrafficSourceInfo from 'cf-components/TrafficSourceInfo'

const useStyles = makeStyles(theme => ({
  avatarDiv: {
    marginRight: 15
  },
  cardTitle: {
    fontSize: '17px',
    fontWeight: 600
  },
  iFrameCardTitle: {
    fontSize: '13px',
    fontWeight: 600
  },
  activityHeader: {
    display: 'flex',
    alignItems: 'center'
  },
  activitySummary: {
    marginLeft: 25,
    display: 'flex',
    alignItems: 'center'
  },
  activityDetail: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '15px',
    padding: 7,
    borderBottom: '1px solid rgba(0,0,0,0.2)',
    cursor: 'pointer'
  },
  iFrameActivityDetail: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px',
    padding: 4,
    borderBottom: '1px solid rgba(0,0,0,0.2)',
    cursor: 'pointer'
  },
  detailsView: {
    paddingLeft: 20,
    width: '100%'
  },
  iFrameDetailsView: {
    width: '100%'
  },
  smallAvatar: {
    height: 28,
    width: 28
  },
  separator: {
    paddingLeft: 8,
    paddingRight: 8,
    display: 'flex',
    alignItems: 'center'
  },
  visitContentStyle: {
    display: 'flex',
    flexDirection: 'column'
  }
}))

export const ActivityIcon = props => {
  const classes = useStyles()
  const activity = activities[props.event]

  const Icon = activity?.icon
  const color = activity?.color
  const iconSize = props.size === 'sm' ? 18 : 28

  return (
    <div className={classes.avatarDiv}>
      <Avatar
        style={{ backgroundColor: color }}
        className={props.size === 'sm' ? classes.smallAvatar : null}
      >
        <Icon style={{ fill: 'white', height: iconSize }} />
      </Avatar>
    </div>
  )
}

const ActivityDetail = props => {
  const classes = useStyles()
  const iFrame = props.iFrame
  const clickFunction = props?.clickFunction

  const openLink = (url) => {
    // remove query params to avoid corrupting visitor data
    window.open(url.split('?')[0], '_blank')
  }

  return (
    <div className={iFrame ? classes.iFrameActivityDetail : classes.activityDetail} onClick={() => clickFunction ? clickFunction() : openLink(props.url)}>
      <ActivityIcon event={props.event} size='sm' />
      <div>
        {props.text}
        {props.extraText}
      </div>
    </div>
  )
}

const DetailedView = props => {
  const classes = useStyles()
  const details = props.details
  const iFrame = props.iFrame

  return (
    <div className={iFrame ? classes.iFrameDetailsView : classes.detailsView}>
      {details.page_views.map((pv, index) => {
        const url = pv.url
        const count = pv.views
        let title = count > 1 ? `${pv.title} - ${count} views` : pv.title
        let extraText = <></>
        if (pv?.visitor_name) {
          title = `${pv.visitor_name} • ${title}`
        }
        if (pv?.time_on_page) {
          extraText = (
            <Typography variant='body2'>
              <VisitInfo
                title='Time on Page'
                icon='timer'
                value={pv.time_on_page}
              />
            </Typography>
          )
        }
        return (
          <ActivityDetail
            event='pageViews'
            text={title}
            url={url}
            key={index}
            iFrame={iFrame}
            extraText={extraText}
          />
        )
      })}
      {details.conversations.map((c, index) => {
        const url = `/#/chat/${c.id}`
        let event = 'conversations'
        let text = `Conversation with bot (${c.name})`
        if (c.live_chat) {
          event = 'liveChats'
          text = `Conversation with agent (${c.agent_name})`
        } else if (c.emails_captured) {
          event = 'emailsCaptured'
          text = `Email captured in conversation with bot (${c.name})`
        }
        return (
          <ActivityDetail
            event={event}
            text={text}
            url={url}
            key={index}
            iFrame={iFrame}
          />
        )
      })}
      {details.meetings.map((m, index) => {
        const url = `/#/chat/${m.conversation_id}`
        const event = 'meetingsBooked'
        const text = `"${m.name}" scheduled with ${m.agent_name}`
        return (
          <ActivityDetail
            event={event}
            text={text}
            url={url}
            key={index}
            iFrame={iFrame}
          />
        )
      })}
      {details.form_submissions.map((form_sub, index) => {
        const url = ''
        const event = 'forms'
        const text = `Submitted form (${form_sub.name})`
        const handleClick = () => {
          props.setSubmissionModalOpen(true)
          props.setSubmissionID(form_sub.id)
          props.setContact()
        }
        return (
          <ActivityDetail
            event={event}
            text={text}
            url={url}
            key={index}
            iFrame={iFrame}
            clickFunction={() => handleClick()}
          />
        )
      })}
    </div>
  )
}

const ActivityLineItems = props => {
  const classes = useStyles()
  const summaryItems = props.summaryItems
  const maxIndex = summaryItems.length - 1
  const iFrame = props.iFrame

  return (
    <div className={classes.activitySummary} style={iFrame ? { fontSize: '11px' } : { undefined }}>
      {summaryItems.map((item, index) => {
        if (index === maxIndex) {
          return (
            <div key={index}><b>{item.count}</b>{item.text}</div>
          )
        } else {
          return (
            <React.Fragment key={index}>
              <div><b>{item.count}</b>{item.text}</div>
              <div className={classes.separator}><DotIcon style={{ fontSize: '0.7em', paddingTop: 3 }} /></div>
            </React.Fragment>
          )
        }
      })}
    </div>
  )
}

export const VisitInfo = props => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Tooltip title={props.title}>
        <Icon icon={props.icon} size='sm' color={props.color} />
      </Tooltip>
      <span style={{ paddingLeft: 5 }}>{props.value}</span>
      {props.extra}
    </div>
  )
}

const VisitEventDropdown = props => {
  const classes = useStyles()
  const visitData = props.visitData || {}
  const { mostRelevantEvent } = getSummary(visitData?.summarized_data)
  const visitedAt = new Date(visitData?.visited_at).toLocaleTimeString()
  const visitorLoc = visitData?.visitor_location
  const timeOnSite = visitData?.time_on_site
  const deviceType = visitData?.device_type
  const referrer = visitData?.referrer
  const campaign = visitData?.campaign
  const utmSource = visitData?.utm_source

  const iFrame = props.iFrame

  return (
    <Accordion style={{ marginTop: 10, marginBottom: 10, marginLeft: 55 }} defaultExpanded={props.defaultExpanded}>
      <AccordionSummary expandIcon={<Icon icon='expand' />}>
        <ActivityIcon event={mostRelevantEvent} size={iFrame ? 'sm' : undefined} />
        <div className={classes.visitContentStyle}>
          <div className={classes.activityHeader}>
            <div className={iFrame ? classes.iFrameCardTitle : classes.cardTitle}>
              {visitData?.visitor_name}
            </div>
            <Typography style={{ marginLeft: 10 }} variant='body2'>
              visited at {visitedAt}
            </Typography>
          </div>
          <div>
            <Typography style={{ display: 'flex', alignItems: 'center' }} variant='body2'>
              {visitorLoc && (
                <>
                  <VisitInfo
                    title="Visitor's Location"
                    icon='location'
                    value={visitorLoc}
                  />
                  <span style={{ marginRight: 10 }} />
                </>
              )}
              {referrer && (
                <>
                  <VisitInfo
                    title='Source'
                    icon='trafficSourceSmall'
                    value={referrer}
                    color='#7F7F7F'
                    extra={
                      <TrafficSourceInfo
                        trafficSource={utmSource}
                        trafficCampaign={campaign}
                        color='#7F7F7F'
                      />
                    }
                  />
                  <span style={{ marginRight: 10 }} />
                </>
              )}
              {deviceType && (
                <>
                  <VisitInfo
                    title='Device Type'
                    icon='laptop'
                    value={deviceType}
                  />
                  <span style={{ marginRight: 10 }} />
                </>
              )}
              {timeOnSite && (
                <>
                  <VisitInfo
                    title='Visit Duration'
                    icon='timer'
                    value={timeOnSite}
                  />
                  <span style={{ marginRight: 10 }} />
                </>
              )}
            </Typography>
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <DetailedView
          details={visitData.details}
          iFrame={iFrame}
          setContact={() => props.setContact(visitData?.visitor_name)}
          setSubmissionID={props.setSubmissionID}
          setSubmissionModalOpen={props.setSubmissionModalOpen}
        />
      </AccordionDetails>
    </Accordion>
  )
}

const ActivityEventsDropdown = props => {
  const classes = useStyles()
  const activityData = props.activityData || {}
  const date = new Date(activityData?.date)
  const timezoneOffset = date.getTimezoneOffset() * 60000
  const { summaryItems, mostRelevantEvent } = getSummary(activityData?.summarized_data)
  const iFrame = props.iFrame

  return (
    <Accordion style={{ marginTop: 10 }} defaultExpanded={props.defaultExpanded}>
      <AccordionSummary expandIcon={<Icon icon='expand' />}>
        <div className={classes.activityHeader}>
          <ActivityIcon event={mostRelevantEvent} size={iFrame ? 'sm' : undefined} />
          <div className={iFrame ? classes.iFrameCardTitle : classes.cardTitle}>
            {dateFormat(new Date(date.getTime() + timezoneOffset), 'mmmm d')}
          </div>
          <ActivityLineItems
            summaryItems={summaryItems}
            iFrame={iFrame}
          />
        </div>
      </AccordionSummary>
      {activityData.site_visits.map((visitData, index) => {
        return (
          <VisitEventDropdown
            key={index}
            visitData={visitData}
            date={date}
            iFrame={iFrame}
            setContact={props.setContact}
            setSubmissionID={props.setSubmissionID}
            setSubmissionModalOpen={props.setSubmissionModalOpen}
            defaultExpanded={props.defaultExpanded}
          />
        )
      })}
    </Accordion>
  )
}

export default ActivityEventsDropdown
