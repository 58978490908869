/* eslint-disable @typescript-eslint/naming-convention */
import Card from 'library/materialUI/Card'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from 'library/materialUI'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { useContext, useEffect } from 'react'
import { TabContext } from '../IntegrationPage/TabProvider'
import { IntegrationPageContext } from '../IntegrationPage/IntegrationPageProvider'

const useStyles = makeStyles((theme) => ({
  subtitle1: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 15
  },
  subtitle2: {
    fontSize: 15,
    fontWeight: 600,
    marginBottom: 10
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  checkboxes: {
    display: 'block'
  },
  bodyText: {
    marginBottom: 30,
    fontSize: 16
  }
}))

const ownerRulesSettings = [
  { key: 'meeting_scheduled', description: 'User who scheduled a meeting' },
  { key: 'last_routed', description: 'User who was last routed' },
  {
    key: 'closed_conversation',
    description: 'User who closed the conversation'
  }
]

interface LeadContactOwnerCardProps {
  save: any
  handleEdit: any
  integrationSettings: any
}

export default function LeadContactOwnerCard ({ save, handleEdit, integrationSettings }: LeadContactOwnerCardProps) {
  const classes = useStyles()
  const { setCanSave, shouldSave } = useContext(TabContext)
  const { handleSaved } = useContext(IntegrationPageContext)

  const setOwnerRule = (checked: boolean, ownerRule: string) => {
    const currentRules = integrationSettings.attributes.config.owner_rules || []

    // The order of this dictionary dictates the
    // priority of the owner rules
    const priorityDict: Record<string, boolean> = {
      meeting_scheduled: false,
      last_routed: false,
      closed_conversation: false
    }

    for (const rule of currentRules) {
      priorityDict[rule] = true
    }

    if (checked) {
      priorityDict[ownerRule] = true
    } else {
      priorityDict[ownerRule] = false
    }

    const newRules: string[] = []

    Object.keys(priorityDict).map((key) => {
      if (priorityDict[key]) {
        newRules.push(key)
      }
      return true
    })

    setCanSave(true)
    handleEdit({ type: 'owner_rules', data: newRules })
  }

  useEffect(() => {
    if (shouldSave) {
      save()
      handleSaved()
    }
  })

  return (
    <Card
      content={
        <>
          <div className={classes.header}>
            <Typography className={classes.subtitle1}>
              Lead/Contact Owner Rules
            </Typography>
          </div>
          <Typography variant='body2' className={classes.bodyText}>
            New leads will be assigned to a Lead Owner based on the following settings, in order.
          </Typography>
          {ownerRulesSettings.map((setting, index) => (
            <FormControlLabel
              key={index}
              className={classes.checkboxes}
              control={
                <Checkbox
                  color='primary'
                  checked={integrationSettings.attributes.config?.owner_rules?.includes(
                    setting.key
                  )}
                  onChange={(e) => setOwnerRule(e.target.checked, setting.key)}
                />
              }
              label={setting.description}
            />
          ))}
        </>
      }
    />
  )
}
