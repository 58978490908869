import React from 'react'
import { Icon } from 'library/materialUI/Icon'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  poweredBy: {
    backgroundColor: '#FFF',
    color: '#777',
    boxShadow: '0px 0px 8px 2px rgba(85,85,85,0.3)',
    borderRadius: 10,
    fontFamily: 'Poppins',
    fontSize: 13,
    display: 'flex',
    marginTop: 5,
    alignItems: 'center',
    cursor: 'pointer',
    height: 45,
    '& p': {
      margin: '10px 15px 10px 0px'
    }
  }

}))

function PoweredBy (props) {
  const classes = useStyles()
  return (
    <div className={classes.poweredBy} onClick={() => window.open('https://getsignals.ai', '_blank')}>
      <div style={{ padding: 10 }}><Icon icon='logo' /></div>
      <p>Powered by Signals</p>
    </div>
  )
}

export default PoweredBy
