import { useState, useEffect, Fragment } from 'react'
import { makeStyles, ThemeProvider } from '@material-ui/core/styles'
import 'jsplumb'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { jsPlumbUtil } from 'jsplumbtoolkit'
import useNodeStyles from './NodeStyles'
import Divider from '@material-ui/core/Divider'
import BranchIcon from '@material-ui/icons/CallSplit'
import OptionPort from '../OptionPort'
import Typography from '@material-ui/core/Typography'
import theme from '../../../../theme'
import { Button, List, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import Chip from '@material-ui/core/Chip'
import FilterSelector from '../../criteria/FilterSelector'
import { Formik, Form, Field, FieldArray } from 'formik'
import TextField from '@material-ui/core/TextField'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import TrashIcon from '@material-ui/icons/DeleteForever'
import AddBoxIcon from '@material-ui/icons/AddBox'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import EditIcon from '@material-ui/icons/Edit'
import ConditionalDivider from 'cf-components/ConditionalDivider'
import { QueryClient, QueryClientProvider } from 'react-query'
import { SessionProvider } from 'session-context'
import { EVENT_TYPE, Emitter } from 'emitter'
import { Modal, ModalSection } from 'library/Modal'

const useStyles = makeStyles(theme => ({
  editButton: {
    textTransform: 'none',
    width: '90px !important',
    marginLeft: 20,
    backgroundColor: 'white',
    border: '1px solid rgba(0,0,0,0.5)',
    boxShadow: 'none'
  },
  groupItem: {
    borderTop: '1px solid #cccccc',
    paddingLeft: 0,
    paddingRight: 0
  },
  subtitle: {
    color: '#999',
    fontFamily: 'Poppins, sans serif',
    fontSize: '12px'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    marginRight: 8
  },
  input: {
    padding: 6,
    borderRadius: 30
  },
  inputRoot: {
    borderRadius: 30,
    paddingLeft: 10
  },
  select: {
    height: 24,
    borderRadius: 30
  },
  fallbackText: {
    marginLeft: 10
  },
  sectionHeader: {
    fontSize: '14px',
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    color: '#4C4C4C'
  },
  divider: {
    marginTop: 5,
    marginBottom: 5
  },
  filterGroup: {
    border: '1px solid #D3D3D3',
    borderRadius: 10,
    backgroundColor: '#F5F5F5'
  },
  groupTitle: {
    fontSize: '0.95em',
    fontFamily: 'Poppins, sans serif',
    marginTop: 10,
    marginLeft: 15
  },
  infoIcon: {
    marginLeft: 5,
    marginBottom: 2,
    fill: '#8e5ae2',
    fontSize: '1.3em'
  }
}))

function isGroupDisabled (values) {
  if (!values.label) {
    return true
  }
  if (!values.filters.length) {
    return true
  }
  for (const filter of values.filters) {
    if (!filter.cmp || !filter.prop) {
      return true
    }
    if (!filter.value) {
      return true
    }
  }
  return false
}

function ConditionGroupEditor (props) {
  const classes = useStyles()

  return (
    <Formik
      initialValues={{ filters: props.filters, label: props.label, id: props.id }}
      onSubmit={values =>
        props.save(values)}
      render={({ values, submitForm, resetForm }) => {
        const disabled = isGroupDisabled(values)
        return (
          <ThemeProvider theme={theme}>
            <Modal
              open={props.open}
              onHide={() => {
                props.onHide()
                resetForm()
              }}
              handleSave={submitForm}
              title='Conditional Branching'
              size='md'
              saveDisabled={disabled}
              helplink='https://help.getsignals.ai/article/ds6twmpyj1-conditional-branching-node'
              helplinkLabel='Learn more about this skill'
              saveIcon='save'
            >
              <Form>
                <ModalSection
                  title='Branch Name'
                  subtitle='This name will appear as the output label on the bot skill. It will not be seen by visitors'
                >
                  <Field
                    name='label'
                  >
                    {({
                      field,
                      form: { touched, errors },
                      meta
                    }) => (
                      <TextField
                        variant='outlined'
                        margin='dense'
                        fullWidth
                        placeholder='Branch Name'
                        {...field}
                      />
                    )}
                  </Field>
                </ModalSection>
                <ModalSection
                  title='Filters'
                  subtitle='Visitors must meet ALL of the criteria listed here to be included in the group'
                >
                  <FieldArray
                    name='filters'
                    render={arrayHelpers => (
                      <div className={classes.filterGroup}>
                        <div className={classes.groupTitle}>
                          Visitor Filter Group
                        </div>
                        <div>
                          {values.filters.map((filter, index) => (
                            <div key={index}>
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <FilterSelector
                                  filter={filter}
                                  removeFilter={() => arrayHelpers.remove(index)}
                                  chatServiceUrl={props.chatServiceUrl}
                                  propPrefix={`filters[${index}]`}
                                />
                                <ConditionalDivider
                                  operator='and'
                                  filterLen={values.filters.length}
                                  index={index}
                                />
                              </MuiPickersUtilsProvider>
                            </div>
                          ))}
                        </div>
                        <Button
                          style={{ marginLeft: 10, marginBottom: 5, textTransform: 'none', color: theme.palette.primary.alt }}
                          startIcon={<AddCircleIcon />}
                          onClick={() => arrayHelpers.push({
                            prop: '',
                            cmp: '',
                            value: []
                          })}
                        >
                          Add filter
                        </Button>
                      </div>
                    )}
                  />
                </ModalSection>
              </Form>
            </Modal>
          </ThemeProvider>
        )
      }}
    />

  )
}

function ConditionalModal (props) {
  const classes = useStyles(props)
  const [groups, setGroups] = useState(props.groups || props.ports)
  const [editingGroupIndex, setEditingGroupIndex] = useState(null)

  function deleteGroupAtIndex (index) {
    const newGroups = [...groups]
    newGroups.splice(index, 1)
    setGroups(newGroups)
  }

  function cancelChanges () {
    setGroups(props.groups || props.ports)
    props.onHide()
  }

  const save = () => {
    props.save({
      ports: groups
    })
  }

  if (!props.open) {
    return <></>
  }

  if (editingGroupIndex) {
    let filters = [{
      prop: '',
      cmp: '',
      value: []
    }]
    let label = ''
    let id = jsPlumbUtil.uuid()

    if (editingGroupIndex > 0) {
      filters = groups[editingGroupIndex].filters
      label = groups[editingGroupIndex].label
      id = groups[editingGroupIndex].id
    }

    return (
      <ConditionGroupEditor
        open
        filters={filters}
        label={label}
        id={id}
        chatServiceUrl={props.chatServiceUrl}
        onHide={() => setEditingGroupIndex(null)}
        save={({ filters, label, id }) => {
          const groupIndexCapture = editingGroupIndex
          setGroups(existingGroups => {
            let newGroups = [...existingGroups]
            if (groupIndexCapture > 0) {
              newGroups.splice(groupIndexCapture, 1, {
                label: label,
                filters: filters,
                id: id
              })
            } else {
              newGroups = existingGroups.concat({
                id: id,
                label: label,
                filters: filters
              })
            }
            return newGroups
          })
          setEditingGroupIndex(null)
        }}
      />
    )
  }

  Emitter.on(EVENT_TYPE.BROWSER_BACK, cancelChanges)

  return (
    <ThemeProvider theme={theme}>
      <Modal
        open={props.open}
        onHide={cancelChanges}
        handleSave={save}
        title='Conditional Branching'
        saveIcon='save'
        helplink='https://help.getsignals.ai/article/ds6twmpyj1-conditional-branching-skill'
        helplinkLabel='Learn more about this skill'
      >
        <ModalSection>
          <Typography style={{ marginBottom: 10, fontSize: '.9em', color: '#808080' }}>
            Arrange conditions in the order in which they should be evaluated.
            The first condition that matches will determine where the conversation
            goes next. If no conditions match, the site visitor will follow the Fallback Path.
          </Typography>
          <Divider className={classes.divider} />
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              startIcon={<AddBoxIcon />}
              color='primary'
              onClick={() => {
                setEditingGroupIndex(-1)
              }}
              variant='outlined'
              style={{ textTransform: 'none' }}
            >
              Add New Branch
            </Button>
          </div>
          <div style={{ minHeight: 150 }}>
            <List
              dense={false}
            >
              {groups.map((group, i) => (
                <Fragment key={group.id}>
                  {group.id === 'default' ? <></> : (
                    <ListItem
                      className={classes.groupItem}
                      draggable
                      onDragOver={(e) => {
                        e.preventDefault()
                      }}
                      onDrop={(e) => {
                        const index = e.dataTransfer.getData('index')
                        const newGroups = [...groups]
                        newGroups.splice(i, 0, newGroups.splice(index, 1)[0])
                        setGroups(newGroups)
                      }}
                      onDragStart={(ev, id) => {
                        ev.dataTransfer.setData('index', i)
                      }}
                    >
                      <DragIndicatorIcon />
                      <Chip
                        label={group.label}
                        style={{ fill: '#DD64E7' }}
                      />
                      <ListItemSecondaryAction style={{ right: 0 }}>
                        {group.id !== 'default' ? (
                          <>
                            <IconButton
                              color='primary' aria-label='edit group' onClick={() => {
                                setEditingGroupIndex(i)
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                            <IconButton
                              color='primary' aria-label='delete group' component='span' onClick={() => {
                                deleteGroupAtIndex(i)
                              }}
                            >
                              <TrashIcon />
                            </IconButton>
                          </>
                        ) : <></>}
                      </ListItemSecondaryAction>

                    </ListItem>
                  )}
                </Fragment>
              ))}

              {groups.filter(i => i.id === 'default').map((group, i) => (
                <ListItem className={classes.groupItem} key='default_real' style={{ paddingRight: 0 }}>
                  <Chip
                    label={group.label}
                  />
                  <ListItemText
                    className={classes.fallbackText} secondary={
                      <>
                        Visitors that DO NOT match any of the above branches will follow this branch
                      </>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </div>
        </ModalSection>
      </Modal>
    </ThemeProvider>
  )
}
const queryClient = new QueryClient()

const ConditionalNode = props => {
  const base = props.base
  const classes = useNodeStyles(props)
  const [chatServiceUrl, setChatServiceUrl] = useState(null)

  const assignKey = data => {
    data._reactKey = data._reactKey || jsPlumbUtil.uuid()
    return data._reactKey
  }

  const saveChanges = ({ ports, branches }) => {
    if (!base.toolkit) {
      return
    }
    const new_ids = ports.map(port => port.id)

    setTimeout(() => {
      base.toolkit.batch(() => {
        const node = base.getNode()
        const new_data =
          { ...base.state, port_order: new_ids }
        base.toolkit.updateNode(node, new_data)
        const oldPorts = node.getPorts()
        const port_ids = oldPorts.map(i => i.id)
        for (const port_id of port_ids) {
          if (!(new_ids.includes(port_id))) {
            base.toolkit.removePort(node, port_id)
          }
        }
        for (const port of ports) {
          if (!(port_ids.includes(port.id))) {
            base.addNewPort('default', port)
          } else {
            const actualPortObject = oldPorts.filter(i => i.id === port.id)[0]
            base.toolkit.updatePort(actualPortObject, {
              label: port.label,
              filters: port.filters
            })
          }
        }
      })
    }, 0)
    base.toolkit.batch(() => {
      props.setModalOpen(false)
    })
  }

  useEffect(() => {
    fetch('/api/auth/me', {
      method: 'GET',
      headers: { 'Content-Type': 'application/vnd.api+json' }
    })
      .then(response => response.json())
      .then(response => {
        if (response.data && response.data.id) {
          setChatServiceUrl(response.data.links.chat_service || 'localhost:8002/api/chat-service/a')
        }
      })
  }, [])

  let sorty_ports = base.state.ports
  if (base.state.port_order) {
    sorty_ports = []
    for (const port_id of base.state.port_order) {
      const port = base.state.ports.filter(port => port.id === port_id)[0]
      if (port) {
        sorty_ports.push(port)
      }
    }
  }

  return (
    <QueryClientProvider client={queryClient}>
      <SessionProvider>
        <ThemeProvider theme={theme}>
          <div>
            <div
              className={`${classes.dragger} ${classes.backgroundAction}`}
            >
              <div className='node-header'>
                <div className={classes.iconDiv}><BranchIcon /></div>
                <div className={classes.cardTitle}>Conditional Branching</div>
              </div>
              <div className='node-body'>
                {sorty_ports.filter(i => {
                  return i.id !== 'default'
                }).map((c) => {
                  return (
                    <OptionPort
                      index={c.id}
                      data={c}
                      key={assignKey(c)}
                      parent={base}
                      kind='backgroundAction'
                    />
                  )
                })}
                {sorty_ports.filter(i => i.id === 'default').map((c) => {
                  return (
                    <OptionPort
                      index={c.id}
                      data={c}
                      key={assignKey(c)}
                      parent={base}
                      kind='backgroundAction'
                    />
                  )
                })}
              </div>
              <jtk-target
                scope='default'
              />
            </div>
          </div>
          <ConditionalModal
            open={props.modalOpen}
            onHide={() => props.setModalOpen(false)}
            save={saveChanges}
            ports={sorty_ports}
            groups={base.state.groups}
            chatServiceUrl={chatServiceUrl}
          />
        </ThemeProvider>
      </SessionProvider>
    </QueryClientProvider>
  )
}
export default ConditionalNode
