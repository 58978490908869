import { useEffect, useState, useContext, useRef } from 'react'
import { HashRouter as Router, Route, Redirect, Switch, useLocation } from 'react-router-dom'
import LoginPage from './pages/registration/LoginPage'
import RegistrationFromInvitePage from './pages/registration/RegistrationFromInvitePage.jsx'
import { ThemeProvider } from '@material-ui/styles'
import { SessionProvider, SessionContext } from './session-context'
import ResetPasswordPage from './pages/registration/ResetPassword'
import ResetPasswordFinishPage from './pages/registration/ResetPasswordFinish'
import theme from './theme'
import MainApp from './pages/MainApp'
import * as Sentry from '@sentry/browser'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import FreeTrialPage from './pages/trial/FreeTrialPage'
import VersionManager from './VersionManager'
import { QueryClient, QueryClientProvider } from 'react-query'
import VerifyAccountPage from './pages/registration/VerifyAccountPage'
import AlternateLoginPage from 'pages/registration/AlternateLoginPage'
import UnsubscribePage from 'pages/UnsubscribePage'
import { getSearchParam } from 'helpers/strings'
import Loader from 'library/loading/Loader'
import ThemePage from 'ThemePage'
import HTMXEventHandler from 'components/HTMXEventHandler'

export const queryClient = new QueryClient()

let env = 'dev'
if (window.location.hostname === 'beta.chatfunnels.com') {
  env = 'beta'
} else if (window.location.hostname === 'app.chatfunnels.com') {
  env = 'prod'
}

Sentry.init({
  dsn: 'https://8314b7c8399a469a9d2333d1a085b8ef@sentry.io/1469865',
  environment: env,
  ignoreErrors: [
    // Strings or regexes that match the error message or error name
    'unauthorized'
  ]
})

function useQueryString () {
  return new URLSearchParams(useLocation().search)
}

function LogoutPage () {
  const [isLoggedOut, setIsLoggedOut] = useState(false)
  const { user, setUser } = useContext(SessionContext)
  useEffect(() => {
    fetch('/api/auth/logout', {
      method: 'POST',
      cache: 'no-store'
    }).then(() => {
      setIsLoggedOut(true)
      if (user) {
        setUser(null)
      }
    })
  }, [setUser, user])

  if (isLoggedOut) {
    return <Redirect to='/' />
  }
  return <div>Logging out...</div>
}

function PrivateRoute ({ children, session, ...rest }) {
  const loginPage = useRef('')
  const [isLoading, setIsLoading] = useState(true)
  const query = useQueryString()
  HandleTenantSpecificLoginPage({ location, setIsLoading, loginPage })
  function render ({ location }) {
    const tenantInfoBundleRaw = query.get('tibdl')
    const tenantInfoBundle = tenantInfoBundleRaw ? JSON.parse(window.atob(tenantInfoBundleRaw)) : {}
    const sessionTenantId = session?.user?.relationships?.tenant?.data?.id
    const sessionTenantIdInt = sessionTenantId ? parseInt(sessionTenantId) : null
    if (isLoading) {
      return (
        <Loader type='spinner' size='lg' />
      )
    }
    if (loginPage.current) {
      return (
        <Redirect
          to={loginPage.current}
        />
      )
    }
    if (sessionTenantIdInt && tenantInfoBundle.t && sessionTenantIdInt !== tenantInfoBundle.t) {
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: location, tenantBundle: { ...tenantInfoBundle, l: tenantInfoBundle.l } }
          }}
        />
      )
    }

    const result = session.user ? (
      children
    ) : (
      <Redirect
        to={{
          pathname: '/login',
          state: { from: location, tenantBundle: tenantInfoBundle }
        }}
      />
    )
    return result
  }
  return (
    <Route
      {...rest}
      render={render}
    />
  )
}

function RoutedApp (props) {
  const location = useLocation()

  useEffect(() => {
    window.userpilot && window.userpilot.reload()
  }, [location])

  return (
    <SessionProvider>
      <SessionContext.Consumer>
        {session => (
          <>
            {session.user === 0 ? (
              <></>
            ) : (
              <Switch>
                <Route
                  exact
                  path='/dev-register'
                  render={props => (<FreeTrialPage {...props} dev />)}
                />
                <Route
                  exact
                  path='/verify-account'
                  component={VerifyAccountPage}
                />
                <Route exact path='/login-foo' component={LoginPage} />
                <Route exact path='/login' component={LoginPage} />
                <Route exact path='/logout' component={LogoutPage} />
                <Route exact path='/snapshot/register' component={FreeTrialPage} />
                <Route exact path='/login/678' component={AlternateLoginPage} />
                <Route path='/login/okta' component={AlternateLoginPage} />
                <Route exact path='/unsubscribe' component={UnsubscribePage} />
                <Route
                  exact
                  path='/reset-password'
                  component={ResetPasswordPage}
                />
                <Route
                  exact
                  path='/reset-password-finish'
                  component={ResetPasswordFinishPage}
                />
                <Route
                  exact
                  path='/accept-user-invite'
                  component={RegistrationFromInvitePage}
                />
                <Route
                  exact
                  path='/app-styling'
                  component={ThemePage}
                />
                <PrivateRoute session={session} path='/'>
                  <MainApp />
                </PrivateRoute>
              </Switch>
            )}
          </>
        )}
      </SessionContext.Consumer>
    </SessionProvider>
  )
}

function App () {
  const resizeOps = () => {
    document.documentElement.style.setProperty('--vh', window.innerHeight * 0.01 + 'px')
  }

  resizeOps()
  window.addEventListener('resize', resizeOps)

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <VersionManager />
            <div style={{ height: '100%' }}>
              <Router>
                <RoutedApp />
                <HTMXEventHandler />
              </Router>
            </div>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </>
  )
}

export default App

function HandleTenantSpecificLoginPage ({ location, setIsLoading, loginPage }) {
  useEffect(() => {
    if (location.hash) {
      const searchString = location.hash.split('?')[1]
      if (searchString) {
        const tenantID = getSearchParam(searchString, 'tid')
        if (tenantID) {
          GetLoginPage({ tenantID }).then((response) => {
            loginPage.current = response
            setIsLoading(false)
          })
        } else { setIsLoading(false) }
      } else { setIsLoading(false) }
    } else { setIsLoading(false) }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

function GetLoginPage ({ tenantID }) {
  return fetch(`/api/tenant_login_page/${tenantID}`, {
    method: 'GET',
    cache: 'no-store'
  })
    .then((response) => response.json())
    .then((response) => response.data.attributes.login_page)
}
