import { useEffect, useState, useRef, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { createLiveViewMap } from './CreateLiveViewMap'
import Popper from '@material-ui/core/Popper'
import { ContactInfoAvatar } from 'cf-components/Avatars'
import { Card, CardHeader, CardContent, Tooltip } from '@material-ui/core'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import LanguageIcon from '@material-ui/icons/Language'
import BusinessIcon from '@material-ui/icons/Business'
import { ReactComponent as BadgeIcon } from 'img/badge_icon.svg'
import { Icon } from 'library/materialUI'
import Chip from '@material-ui/core/Chip'
import Avatar from '@material-ui/core/Avatar'
import { SignalsScoreGear } from 'cf-components/SignalsScoreGear'
import { Sound } from 'library/sounds/sound'
import { SessionContext } from 'session-context'
import { Link } from 'react-router-dom'
import { truncateStringByWidth } from 'library/helpers'

const useStyles = makeStyles(theme => ({
  card: {
    background: 'rgba(255, 255, 255, 0.1)',
    backdropFilter: 'blur(5px)',
    borderRadius: 0,
    width: 300
  },
  tooltipCardContent: {
    paddingTop: 5,
    paddingRight: 20,
    paddingLeft: 20,
    color: '#D4D4D4',
    background: 'rgba(70, 70, 70, 0.5)',
    fontSize: 18
  },
  tooltipCardHeader: {
    paddingBottom: 10,
    paddingTop: 10,
    paddingRight: 20,
    paddingLeft: 20,
    background: 'rgba(92, 92, 92, 0.5)'
  },
  iconDiv: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginBottom: 7,
    maxWidth: 250
  },
  icon: {
    fill: theme.palette.secondary.main,
    marginRight: 5,
    fontSize: '1.5em',
    marginBottom: 1
  },
  messageChip: {
    backgroundColor: 'white',
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
    '&:focus': {
      backgroundColor: 'white'
    }
  },
  chipTail: {
    position: 'relative',
    top: 38,
    left: 4,
    width: 0,
    height: 0,
    borderTop: '10px solid transparent',
    borderBottom: '10px solid transparent',
    borderRight: '10px solid white',
    transform: 'rotate(200deg)'
  },
  mapIcon: {
    cursor: 'pointer',
    zIndex: 1000,
    color: 'rgba(0, 0, 0, 0.54)',
    '&:hover': {
      color: theme.palette.primary.main
    }
  }
}))

function LiveViewTooltip (props) {
  const classes = useStyles()
  const participant = props.participant
  const truncWidth = 210

  if (participant) {
    const company = participant.company
    return (
      <Card className={`${classes.card} tooltipCard`}>
        <CardHeader
          avatar={
            <ContactInfoAvatar
              contact={participant.person}
              participantID={participant.id}
              size='lg'
              backgroundColor='#505050'
              color='#fff'
              oppStatus={participant.opportunityStatus}
              liveView
            />
          }
          className={classes.tooltipCardHeader}
        />
        <CardContent className={classes.tooltipCardContent}>
          <div>
            {participant?.person?.attributes?.title ? (
              <div className={classes.iconDiv}>
                <BadgeIcon className={classes.icon} style={{ strokeWidth: 0, height: '19px', marginRight: '5px' }} />
                {truncateStringByWidth(participant?.person?.attributes?.title, truncWidth, true)}
                {participant?.fromBuyingCommittee &&
                  <div style={{ marginLeft: '5px' }}>
                    <Icon icon='buyingCommittee' />
                  </div>}
              </div>) : null}
            <div className={classes.iconDiv}>
              <BusinessIcon className={classes.icon} />
              {participant.domain &&
                <Link to={`/accounts/${participant.domain}`}>
                  <div style={{ marginRight: 5 }}>
                    {truncateStringByWidth(company || participant.sixSenseName, truncWidth, true)}
                  </div>
                </Link>}
              {!participant.domain &&
                <div style={{ marginRight: 5 }}>
                  {truncateStringByWidth(company || participant.sixSenseName, truncWidth, true)}
                </div>}
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {participant.isICP &&
                  <div style={{ marginRight: '5px' }}>
                    <Tooltip title='This company matches your Ideal Customer Profile (ICP)'>
                      <div>
                        <Icon icon='icp' />
                      </div>
                    </Tooltip>
                  </div>}
                <SignalsScoreGear score={participant.signalsScore} specificSize={50} liveView />
              </div>
            </div>
            <div className={classes.iconDiv} style={{ flexWrap: 'nowrap' }}>
              <LocationOnIcon className={classes.icon} />
              {truncateStringByWidth(participant.location, truncWidth, true)}
            </div>
            <div className={classes.iconDiv} style={{ flexWrap: 'nowrap' }}>
              <LanguageIcon className={classes.icon} />
              {truncateStringByWidth(participant.currentPage, truncWidth, true)}
            </div>
          </div>
        </CardContent>
      </Card>
    )
  }

  return (<></>)
}

function LiveViewMap (props) {
  const classes = useStyles()
  const { userObject } = useContext(SessionContext)
  const [hoverParticipant, setHoverParticipant] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null)
  const [events, setEvents] = useState(null)
  const [mostRelevantEvent, setMostRelevantEvent] = useState(null)
  const [initialized, setInitialized] = useState(false)
  const chartRef = useRef(null)
  const dataRef = useRef(null)
  const currentlyFocused = useRef(null)
  const rotateRef = useRef(props.autoRotate)
  const liveViewSoundSettings = userObject.notificationSettings?.live_view_map || null
  const displayRegion = props.displayRegion
  const autoRotate = props.autoRotate
  const data = props.data
  const showHidden = props.showHidden
  const autoFocus = props.autoFocus

  const handleParticipantSelect = props.handleParticipantSelect

  const LiveActionCallout = ({ icon, iconColor, message, sLink, name, avatar }) => {
    const classes = useStyles()
    // const [width, setWidth] = useState(0)

    // setTimeout(() => {
    //   setWidth(1000)
    // }, 3000)

    return (
      <>
        <div className={classes.chipTail} />
        <Chip
          className={classes.messageChip}
          avatar={
            <Avatar style={{ backgroundColor: iconColor }}>
              {icon !== 'pointer' ? <Icon color='white' icon={icon} /> : <Icon style={{ fill: '#FFFFFF' }} icon={icon} specificSize={15} />}
            </Avatar>
          }
          label={
            <div style={{ maxWidth: 1000 }}>
              {message} {name ? `${sLink} ${name}` : ''}
            </div>
          }
          // Using delete because chip puts that component on the right side
          onDelete={avatar ? () => { /* do nothing */ } : null}
          deleteIcon={<Avatar src={avatar} style={{ cursor: 'default' }} />}
        />
      </>
    )
  }

  const liveActionCalloutMapping = {
    'visitor-id': { icon: 'addContact', iconColor: '#359FFD', message: 'Visitor Identified', sLink: 'as' },
    'live-chat': { icon: 'liveChat', iconColor: '#D75E7D', message: 'Live chat started', sLink: 'with' },
    'first-visit': { icon: 'pointer', iconColor: '#52B8C4', message: 'First-time visitor', sLink: 'named' },
    'return-visit': { icon: 'replay', iconColor: '#5C66D7', message: 'Returning visitor', sLink: 'named' },
    'calendar-drop': { icon: 'dateRange', iconColor: '#808080', message: 'Calendar dropped', sLink: 'by' },
    'email-visitor': { icon: 'email', iconColor: '#9933FF', message: 'Visitor from Signals email', sLink: 'named' },
    'high-intent-page-visit': { icon: 'webPage', iconColor: '#FD9038', message: 'Visit on high-intent page', sLink: 'by' },
    'meeting-booked': { icon: 'eventAvailable', iconColor: '#81AF20', message: 'Meeting Booked', sLink: 'with' },
    'conversation-started': { icon: 'message', iconColor: '#FE5F45', message: 'Conversation Started', sLink: 'with' }
  }

  useEffect(() => {
    if (!props.initializing) {
      const id = 'chartdiv'
      const data = props.data
      rotateRef.current = autoRotate
      if (data) {
        if (initialized) {
          chartRef.current = createLiveViewMap({ id, data, setAnchorEl, handleParticipantSelect, setHoverParticipant, chartRef, dataRef, displayRegion, rotateRef, setEvents, setMostRelevantEvent, autoFocus, showHidden, currentlyFocused })
        } else {
          setTimeout(() => {
            chartRef.current = createLiveViewMap({ id, data, setAnchorEl, handleParticipantSelect, setHoverParticipant, chartRef, dataRef, displayRegion, rotateRef, setEvents, setMostRelevantEvent, autoFocus, showHidden, currentlyFocused })
            setInitialized(true)
          }, 2000)
        }
      }
      setAnchorEl(null)
    }
    // eslint-disable-next-line
  }, [props.refresh, props.initializing, autoRotate])

  useEffect(() => {
    const id = 'chartdiv'
    if (chartRef.current) {
      rotateRef.current = autoRotate
      chartRef.current.dispose()
      chartRef.current = null
      chartRef.current = createLiveViewMap({ id, data, setAnchorEl, handleParticipantSelect, setHoverParticipant, chartRef, dataRef, displayRegion, rotateRef, setEvents, setMostRelevantEvent, autoFocus, currentlyFocused })
    } else {
      rotateRef.current = autoRotate
      chartRef.current = createLiveViewMap({ id, data, setAnchorEl, handleParticipantSelect, setHoverParticipant, chartRef, dataRef, displayRegion, rotateRef, setEvents, setMostRelevantEvent, autoFocus, currentlyFocused })
    }
    // eslint-disable-next-line
  }, [props.displayRegion])

  useEffect(() => {
    if (mostRelevantEvent) {
      const soundSetting = liveViewSoundSettings?.[mostRelevantEvent]
      if (soundSetting && !soundSetting.isMuted) {
        Sound({ sound: soundSetting.sound })
      }
      setMostRelevantEvent(null)
    }
    // eslint-disable-next-line
  }, [mostRelevantEvent])

  useEffect(() => {
    if (chartRef.current && showHidden) {
      chartRef.current.zoomControl.plusButton.show()
      chartRef.current.zoomControl.minusButton.show()
    } else if (chartRef.current && !showHidden) {
      chartRef.current.zoomControl.plusButton.hide()
      chartRef.current.zoomControl.minusButton.hide()
    }
  }, [showHidden])

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <div style={{ height: '100%', width: '100%', position: 'absolute' }}>
      <div id='chartdiv' style={{ width: '100%', height: '100%' }} />
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        <LiveViewTooltip
          participant={hoverParticipant}
        />
      </Popper>
      {events && events.map((event, index) => {
        return (
          <Popper
            key={index}
            id={event.id}
            open={!event.hidden}
            anchorEl={event.anchorEl}
            placement='top-start'
          >
            <LiveActionCallout {...liveActionCalloutMapping[event.type]} name={event.name} avatar={event.avatar} />
          </Popper>
        )
      })}
      {showHidden && (
        <Tooltip title={!document.fullscreenElement ? 'Full screen' : 'Exit full screen'} placement='left'>
          <div className={classes.mapIcon} style={{ position: 'absolute', bottom: 100, right: 5 }}>
            {!document.fullscreenElement ? (
              <Icon icon='fullScreen' specificSize={30} onClick={props.setFullScreen} color='white' />
            ) : (
              <Icon icon='fullScreenExit' specificSize={30} onClick={props.setFullScreen} color='white' />
            )}
          </div>
        </Tooltip>
      )}
    </div>
  )
}

export default LiveViewMap
