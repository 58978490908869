import CoachingEventHandler from './CoachingEventHandler'

function HTMXEventHandler () {
  return (
    <>
      <CoachingEventHandler />
    </>
  )
}

export default HTMXEventHandler
