import { doPatch, doPost, doGet, doDelete, doStreamFetch } from './api'
import { SearchParams } from '../classes/queryHelpers'
import { toTitleCase } from 'library/helpers'

function contactSearch ({ contactIDs }: { contactIDs: string[] }) {
  const params = new URLSearchParams()
  const path = '/contacts:search'

  const data = {
    type: 'contact_search',
    attributes: {}
  }

  if (contactIDs) {
    data.attributes = {
      contact_ids: contactIDs
    }
  }

  return doPost({
    useChatURL: true,
    path: path + '?' + params.toString(),
    data
  })
}

function deleteContacts ({ contactIDs }: { contactIDs: string[] }) {
  return doDelete({
    useChatURL: true,
    path: '/contacts',
    data: {
      attributes: {
        contact_ids: contactIDs
      }
    }
  })
}

interface getContactParams {
  personID: string
  includes?: string[]
  contactID?: string
  authHeader: Record<string, string>
}

function transformSeniority (seniority: string) {
  if (seniority.toLowerCase() === 'vp') {
    return 'VP'
  }
  if (seniority.toLowerCase() === 'ceo') {
    return 'CEO'
  }

  seniority = seniority.replace('_', ' ')
  return toTitleCase(seniority)
}

function getContact ({ personID, includes, contactID, authHeader }: getContactParams) {
  let includeString = ''
  if (includes) {
    includeString = '?include=' + includes.join(',')
  }

  const id = contactID || personID
  const path = `/contacts/${id}${includeString}`

  return doGet({
    path,
    useChatURL: true,
    extraHeaders: authHeader
  })
    .then(personResponse => {
      let attr = personResponse.data.attributes
      if (attr?.seniority) {
        attr.seniority = transformSeniority(attr.seniority)
      }
      const included = personResponse.included || []
      for (const inc of included) {
        if (inc.type === 'accounts') {
          attr = { ...attr, account_id: inc.id }
        }
        if (inc.type === 'users') {
          attr = {
            ...attr,
            created_by_user: {
              id: inc.id,
              name: inc.attributes.name
            }
          }
        }
        if (inc.type === 'meetings') {
          attr = { ...attr, meetings: inc.data }
        }
      }
      const person = personResponse.data
      person.attributes = attr
      return person
    })
}

interface saveContactParams {
  personAttributes: Record<string, any>
  personID: string
  account_id: string
}
function saveContact ({ personAttributes, personID, account_id }: saveContactParams) {
  const data = {
    type: 'people',
    attributes: personAttributes,
    relationships: {}
  }

  if (personID) {
    data.id = parseInt(personID)
  }

  if (account_id) {
    data.relationships.account = {
      data: { type: 'accounts', id: parseInt(account_id) }
    }
  } else {
    data.relationships.account = {
      data: { type: 'accounts', id: '' }
    }
  }
  let path = '/contacts'
  if (personID) {
    path = `${path}/${personID}`
    return doPatch({ useChatURL: true, data, path })
  } else {
    return doPost({ useChatURL: true, data, path })
  }
}

function createContact ({ personAttributes }: { personAttributes: Record<string, any> }) {
  const path = '/contacts'
  const data = {
    type: 'contacts',
    attributes: personAttributes,
    relationships: {}
  }

  return doPost({
    path,
    useChatURL: true,
    data
  })
}

function getLiveView () {
  // Maxing page size at 500 for map and total participant counts
  const searchParams: SearchParams = {
    pageSize: 500,
    pageNumber: 1
  }
  return doGet({ useChatURL: false, path: '/api/core/live_view', searchParams })
}

function getLiveViewLightLoad () {
  return doGet({ useChatURL: false, path: '/api/core/live_view_light' })
}

function getLiveViewOld () {
  return doGet({ useChatURL: true, path: '/live_view' })
}

function getTrackingParameters ({ personID }: { personID: string }) {
  const path = '/tracking/utm_parameters'
  const data = {
    id: personID,
    attributes: {
      person_id: personID
    }
  }

  return doPost({
    useChatURL: true,
    path,
    data
  })
}

function getContactInsights ({ id, question }: { id: number, question: string }) {
  const data = {
    attributes: {
      id,
      question
    }
  }
  return doStreamFetch({ path: '/api/core/ai/contact_insights', data })
}

export {
  contactSearch,
  deleteContacts,
  getContact,
  saveContact,
  createContact,
  getLiveView,
  getLiveViewLightLoad,
  getLiveViewOld,
  getTrackingParameters,
  getContactInsights
}
