/* eslint-disable @typescript-eslint/naming-convention */
import {
  useState,
  useContext,
  useReducer,
  useRef,
  ChangeEvent,
  MutableRefObject
} from 'react'
import AvatarEditor from 'react-avatar-editor'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import Dropzone from 'react-dropzone'
import PhotoIcon from '@material-ui/icons/AddPhotoAlternateOutlined'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import axios from 'axios'
import base64 from 'base64-js'
import MuiDialogContent from '@material-ui/core/DialogContent'
import { track } from 'userpilot.js'
import UnsavedChangesModal from 'cf-components/UnsavedChangesModal'
import { MyProfileContext } from 'pages/settings/MyProfileProvider'
import { Modal, ModalSection } from 'library/Modal'

const useStyles = makeStyles(theme => ({
  content: {
    margin: 'auto',
    width: '50%',
    textAlign: 'center',
    verticalAlign: 'center'
  },
  editZone: {
    height: 280,
    display: 'flex'
  },
  inputSlider: {
    verticalAlign: 'center',
    marginLeft: 5
  },
  controls: {
    display: 'flex',
    margin: 'auto'
  },
  saveButton: {
    marginLeft: 20,
    marginTop: 14
  },
  closeButton: {
    marginRight: 20,
    marginTop: 14,
    float: 'right'
  },
  buttonProgress: {
    position: 'absolute',
    marginLeft: -3,
    marginTop: -5,
    top: '50%',
    left: '50%'
  },
  wrapper: {
    position: 'relative',
    float: 'left'
  },
  footer: {
    height: 65
  }
}))

const dropzoneStyles = makeStyles(theme => ({
  leftIcon: {
    marginRight: theme.spacing(1),
    color: 'black'
  },
  photoIcon: {
    width: 50,
    height: 50,
    color: 'gray'
  },
  dropzone: {
    cursor: 'pointer',
    height: 280,
    display: 'flex'
  },
  paragraph: {
    color: 'gray',
    fontSize: 16,
    marginBottom: 0,
    marginTop: 10
  },
  paragraph2: {
    color: 'gray',
    fontSize: 15,
    marginBottom: 10,
    marginTop: 0
  },
  content: {
    margin: 'auto',
    width: '50%',
    textAlign: 'center',
    verticalAlign: 'center'
  }
}))

interface PhotoDropZoneProps {
  handler: (file: any) => void
}

function PhotoDropzone ({ handler }: PhotoDropZoneProps) {
  const classes = dropzoneStyles()

  const handleDrop = (acceptedFile: Array<any>) => {
    handler(acceptedFile[0])
  }

  return (
    <Dropzone onDrop={handleDrop}>
      {({ getRootProps, getInputProps }) => (
        <MuiDialogContent
          dividers
          {...getRootProps()}
          className={classes.dropzone}
        >
          <input {...getInputProps()} />
          <div className={classes.content}>
            <PhotoIcon className={classes.photoIcon} />
            <p className={classes.paragraph}>Drag a profile photo here</p>
            <p className={classes.paragraph2}>-or-</p>
            <Button variant='outlined' size='small' style={{ textTransform: 'none', padding: '5px 15px' }}>
              <CloudUploadIcon className={classes.leftIcon} />
              Upload from computer
            </Button>
          </div>
        </MuiDialogContent>
      )}
    </Dropzone>
  )
}

interface PhotoEditorProps {
  editor: MutableRefObject<AvatarEditor | null>
  photo: any
}

function PhotoEditor ({ editor, photo }: PhotoEditorProps) {
  const classes = useStyles()

  const [state, dispatch] = useReducer(reducer, {
    scale: 1,
    position: { x: 0.5, y: 0.5 },
    rotate: 0,
    height: 220,
    width: 220,
    border: 5
  })

  function reducer (state: any, action: { type: string, name: any, value: any }) {
    switch (action.type) {
      case 'update':
        return { ...state, [action.name]: action.value }
      default:
        throw new Error()
    }
  }

  const handlePositionChange = (position: any) => {
    dispatch({ type: 'update', name: 'position', value: position })
  }

  const handleScale = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target) {
      const scale = parseFloat(e.target.value)
      dispatch({ type: 'update', name: 'scale', value: scale })
    }
  }

  return (
    <div className={classes.content}>
      <AvatarEditor
        ref={editor}
        image={photo}
        width={state.width}
        height={state.height}
        position={state.position}
        rotate={parseFloat(state.rotate)}
        border={state.border}
        scale={state.scale}
        borderRadius={220}
        onPositionChange={handlePositionChange}
      />
      <br />
      <div className={classes.controls}>
        <div className={classes.controls}>
          <span style={{ fontWeight: 'bold' }}>ZOOM</span>
          <input
            name='scale'
            type='range'
            onChange={handleScale}
            min='1'
            max='2'
            step='0.01'
            defaultValue='1'
            className={classes.inputSlider}
          />
        </div>
      </div>
    </div>
  )
}

interface AvatarModalProps {
  user_id: string
  onHide: () => void
  updateProfilePicture: (url: string) => void
  show: boolean
  editable: boolean
}

export default function AvatarModal ({ user_id, onHide, updateProfilePicture, show }: AvatarModalProps) {
  const [photo, setPhoto] = useState(null)
  const [loading, setLoading] = useState(false)
  const [unsavedChangesModal, setUnsavedChangesModal] = useState(false)
  const { updateContactInfo, profileDisabled } = useContext(MyProfileContext)
  const editor = useRef<AvatarEditor | null>(null)

  const editMode = photo != null

  function closeModal () {
    setPhoto(null)
    onHide()
    setLoading(false)
  }

  function performOauth () {
    fetch('/api/linkedin/oauth', {
      method: 'GET',
      headers: { 'Content-Type': 'application/vnd.api+json' }
    })
      .then(response => response.json())
      .then(response => {
        sessionStorage.setItem('redirect_url', window.location.href)
        window.location.href = response.oauth_start_url
      })
  }

  function savePhoto () {
    const img = editor.current ? base64.toByteArray(
      editor.current
        .getImageScaledToCanvas()
        .toDataURL('image/png')
        .replace(/^data:image\/(png|jpg);base64,/, '')
    ) : null
    setLoading(true)
    fetch('/api/profile_pictures/' + user_id, {
      method: 'POST',
      headers: { 'Content-Type': 'application/vnd.api+json' },
      body: JSON.stringify({
        data: {
          type: 'profile_pictures',
          attributes: {},
          relationships: {}
        }
      })
    })
      .then(response => response.json())
      .then(url_response => {
        const profile_picture_url =
          url_response.data.attributes.profile_picture_url
        const signed_url = url_response.meta.signed_url
        axios
          .put(signed_url, img, {
            headers: {
              'Content-type': 'image/png',
              'x-goog-acl': 'public-read'
            }
          })
          .then(() => {
            updateProfilePicture(profile_picture_url)
            closeModal()
            track('Profile Picture Uploaded')
          })
          .catch(error => {
            console.log(error)
            closeModal()
          })
      })
      .catch(error => {
        console.log(error)
        closeModal()
      })
  }

  function handleUseLinkedInPhoto () {
    if (!profileDisabled) {
      setUnsavedChangesModal(true)
    } else {
      performOauth()
    }
  }

  const handleUnsavedChangesModalSave = () => {
    setUnsavedChangesModal(false)
    updateContactInfo()
  }

  return (
    <>
      {unsavedChangesModal &&
        <UnsavedChangesModal
          modalOpen={unsavedChangesModal}
          setModalOpen={() => ({})}
          save={() => handleUnsavedChangesModalSave()}
          cancelAction={() => setUnsavedChangesModal(false)}
        />}
      <Modal
        open={show}
        onHide={closeModal}
        title='Select profile picture'
        size='sm'
        handleSave={savePhoto}
        saveIcon='save'
        leftBtnText={!photo ? 'Use LinkedIn Photo' : undefined}
        leftBtnFunction={handleUseLinkedInPhoto}
        leftBtnIcon='smallLinkedIn'
        saveDisabled={loading || !editMode}
      >
        <ModalSection>
          {editMode ? (
            <PhotoEditor photo={photo} editor={editor} />
          ) : (
            <PhotoDropzone handler={setPhoto} />
          )}
        </ModalSection>
      </Modal>
    </>
  )
}
