import { useContext, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AppPage from 'cf-components/AppPage'
import { Icon } from 'library/materialUI/Icon'
import { Button, TextBox, Tooltip } from 'library/materialUI'
// import ScraperModal from './ScraperModal'
// import ScraperSummary from 'classes/scraperSummary'
// import Loader from 'library/loading/Loader'
// import { ApiContext } from 'api-context'
// import { Emitter, EVENT_TYPE } from 'emitter'
// import DeleteModal from 'cf-components/DeleteModal'
import { AIDirective, defaultAIDirective, getGlobalOnboarding, saveTrainingData } from 'api/ai_settings'
import { ShareContext } from 'share-context'
import { INSIGHTS_PLAN_NAME, SNAPSHOT_PLAN_NAME } from 'library/constants'
import ScoringUpgradeModal from '../Scoring/ScoringUpgradeModal'
import { UpgradeBanner } from 'pages/billing/UpgradeBanner'

const useStyles = makeStyles(theme => ({
  container: {
    margin: '20px',
    padding: '20px',
    backgroundColor: '#FFFFFF',
    border: '1px solid #D3D3D3',
    borderRadius: '5px',
    justifyContent: 'center'
  },
  iconDiv: {
    marginRight: '20px',
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    backgroundColor: '#343434',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#343434',
    display: 'flex'
  },
  lightText: {
    fontSize: '14px',
    color: '#808080'
  },
  listHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  divStyle: {
    marginBottom: '20px',
    marginTop: '20px'
  },
  buttonStyle: {
    marginLeft: 'auto',
    marginRight: '10px'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '10px',
    alignItems: 'center'
  }
}))

// const mywindow = window as any

export default function AIContentPage () {
  const classes = useStyles()
  const { planName } = useContext(ShareContext)
  const hasPageAccess = planName() !== SNAPSHOT_PLAN_NAME && planName() !== INSIGHTS_PLAN_NAME
  const [salesModalOpen, setSalesModalOpen] = useState(false)
  const [onboardingObject, setOnboardingObject] = useState<AIDirective>(defaultAIDirective())
  const [onboardingInstructions, setOnboardingInstructions] = useState<string>('')
  const [saveDisabled, setSaveDisabled] = useState<boolean>(true)

  // // const url = '/api/core/web/scraper_summary/list'

  // function confirmDelete () {
  //   deleteTrainingData(deleteCoachingID).then(() => {
  //     mywindow.htmx.trigger('#training-data-list', 'revealed')
  //     setDeleteCoachingID(0)
  //     setDeleteCoachingModalOpen(false)
  //   })
  // }

  // useEffect(() => {
  //   try {
  //     mywindow.htmx.logger = function (elt: any, event: any, data: any) {
  //       if (console) {
  //         console.log('INFO:', event, elt, data);
  //       }
  //     }
  //     // const ids = ['htmx-table', 'htmx-table2']
  //     const ids = ['htmx-table2']
  //     for (const id of ids) {
  //       const d = document.getElementById(id)
  //       mywindow.htmx.process(d)
  //       mywindow._hyperscript.processNode(d)
  //     }
  //     mywindow.addEventListener('rowEvent', function (e: any) {
  //       const tableID = e.detail.tableID
  //       const rowID = e.detail.rowID
  //       const rowAction = e.detail.action
  //       if (tableID === 'scraper-summary-list-body') {
  //         switch (rowAction) {
  //           case 'refresh':
  //             ScraperSummary.doScrape(rowID)
  //             break
  //           case 'delete':
  //             mywindow.deleteRow = e.detail.tr
  //             setDeleteObject(new ScraperSummary({ row: { id: rowID } }))
  //             break
  //           default:
  //             console.warn('Unknown row action', rowAction)
  //         }
  //       } else {
  //         console.log('different table event: ', tableID)
  //       }
  //     });

  //   } catch (e) {
  //     console.log(e)
  //   }
  //   // eslint-disable-next-line
  // }, [hasPageAccess])

  // Emitter.on(EVENT_TYPE.DATA_CHANGED, (data: any) => {
  //   if (data?.eventURL.includes('scraper_summar')) {
  //     if (data?.method === 'DELETE') {
  //       if (mywindow.deleteRow) {
  //         mywindow.htmx.swap(
  //           mywindow.deleteRow,
  //           '',
  //           {
  //             swapDelay: 1000,
  //             swapStyle: 'outerHTML'
  //           })
  //         mywindow.deleteRow = null
  //       } else {
  //         console.log('no row anchor')
  //       }
  //     } else if (data?.method === 'POST') {
  //       mywindow.htmx.trigger('#scraper-summary-list', 'revealed')
  //     }
  //   } else {
  //     console.log('no match', data)
  //   }
  // })

  useEffect(() => {
    getGlobalOnboarding().then(response => {
      if (response && response.length) {
        setOnboardingInstructions(response[0].prompt)
        setOnboardingObject(response[0])
      }
    })
  }, [])

  function handleUploadClick () {
    document.getElementById('fileInput')?.click()
  }

  function saveOnboarding () {
    const directive = {
      ...onboardingObject,
      prompt: onboardingInstructions,
      type: 'onboarding',
      // eslint-disable-next-line
      cloud_agent_id: 0
    }

    saveTrainingData(directive).then(() => {
      setSaveDisabled(true)
    })
  }

  const saveProps = {
    disabled: saveDisabled,
    save: saveOnboarding,
    saveText: 'Save Onboarding',
    validPaths: 'ai_content'
  }

  function handleFileChange (event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0]
      const reader = new FileReader()
      reader.onload = (e) => {
        const result = e.target?.result
        if (typeof result === 'string') {
          setOnboardingInstructions(result)
          setSaveDisabled(false)
        }
      }
      reader.readAsText(file)
    }
  }

  return (
    <AppPage
      title='Content for AI'
      padding={10}
      saveProps={saveProps}
      actionText='Save Onboarding'
    >
      <ScoringUpgradeModal open={salesModalOpen} onHide={() => setSalesModalOpen(false)} />
      {!hasPageAccess && (
        <UpgradeBanner
          prompt='Upgrade for AI-powered features, including Chatbots and Signals score'
          action={() => setSalesModalOpen(true)}
        />
      )}
      {hasPageAccess && (
        <>
          {/* <div className={classes.container}>
            <div className={classes.lightText}>
              Sync your website and other content to train Signals AI for things like Signals Score, AI Chatbots, and more.
            </div>
          </div> */}
          {/* <div className={classes.container}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div className={classes.iconDiv}>
                <Icon icon='webPage' size='md' color='white' />
              </div>
              <div>
                <div className={classes.title}>
                  Websites
                </div>
                <div className={classes.lightText}>
                  Train the AI on any public website. Sites will be synced every 30 days.
                </div>
              </div>
              <div className={classes.buttonStyle}>
                <Button
                  variant='outlined'
                  size='medium'
                  onClick={() => setModalOpen(true)}
                >
                  Add Site
                </Button>
              </div>
            </div>
            <div id='htmx-table' style={{ backgroundColor: 'white', marginTop: '20px' }}>
              <div
                id='scraper-summary-list'
                hx-get={url}
                hx-trigger='revealed'
                hx-indicator='.scraper-summary-list-loader'
              >
                <div style={{ margin: 300 }}>
                  <Loader size='lg' />
                </div>
              </div>
            </div>
          </div> */}
          <div className={classes.container}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <div>
                <div className={classes.title}>
                  Global Onboarding
                  <Tooltip title='Powered by Artificial Intelligence'>
                    <div>
                      <Icon icon='aiSparkles' />
                    </div>
                  </Tooltip>
                </div>
                <div className={classes.lightText}>
                  Introduce every Cloud Agent to your company's mission and objectives. Update the global onboarding here.
                </div>
              </div>
              <Button
                variant='outlined'
                onClick={handleUploadClick}
                style={{ marginTop: '10px', textTransform: 'none' }}
              >
                <div style={{ marginRight: '5px' }}>
                  <Icon icon='newUpload' size='sm' />
                </div>
                Upload
              </Button>
            </div>
            <div id='onboarding-instructions' style={{ marginTop: '20px' }}>
              <TextBox
                value={onboardingInstructions}
                onChange={(value: string) => {
                  setOnboardingInstructions(value)
                  setSaveDisabled(false)
                }}
                rows={15}
              />
              <input
                id='fileInput'
                type='file'
                accept='.txt'
                style={{ display: 'none' }}
                onChange={handleFileChange}
              />
            </div>
          </div>

          {/* <div>
            <ScraperModal open={modalOpen} setOpen={setModalOpen} />
            <CoachingModal open={coachingModalOpen} setOpen={setCoachingModalOpen} onSave={onCoachingSave} trainingData={trainingObject} />
            <DeleteModal open={deleteCoachingModalOpen} title='Delete Coaching' object='Coaching data' onHide={() => setDeleteCoachingModalOpen(false)} confirmAction={confirmDelete} />
          </div> */}
        </>)}
    </AppPage>
  )
}
