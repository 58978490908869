import { makeStyles } from '@material-ui/core/styles'
import { Actions, ActionsComponent } from 'cf-components/table/ActionsComponent'
import SortButton from 'cf-components/table/filter-bar/SortButton'
import SearchBar from 'components/SearchBar'
import { List, ListItem, Avatar, Divider } from 'library/materialUI'
import { useContext, useReducer } from 'react'
import { truncateString } from 'library/helpers'
import { getFilteredRows } from 'cf-components/table/filter-bar/filterHelpers'
import { ScrollBox } from 'cf-components/ScrollBox'
import SkeletonLoader from 'library/loading/skeleton/SkeletonLoader'
import FilterButton from 'library/table/FilterButton'
import { ApiContext } from 'api-context'

const useStyles = makeStyles(theme => ({
  itemCard: {
    display: 'grid',
    gridTemplateColumns: '40px auto',
    gridGap: 15,
    padding: 5
  },
  itemName: {
    display: 'flex',
    fontWeight: 600
  },
  itemActive: {
    display: 'flex',
    alignItems: 'center',
    color: '#81AF20',
    fontWeight: 0,
    fontSize: '12px',
    marginLeft: 10,
    backgroundColor: '#E6EFD2',
    borderRadius: 5,
    padding: 5
  },
  itemInactive: {
    display: 'flex',
    alignItems: 'center',
    color: '#868686',
    fontWeight: 0,
    marginLeft: 10,
    fontSize: '12px',
    backgroundColor: '#E5E5E5',
    borderRadius: 5,
    padding: 5
  },
  subtitle: {
    fontSize: '13px',
    fontStyle: 'italic',
    marginBottom: '1px'
  },
  list: {
    padding: 0
  },
  listItem: {
    borderBottom: '1px solid #0000001A',
    color: '#000000BF',
    overflowWrap: 'anywhere'
  },
  listItemSelected: {
    borderBottom: '1px solid #0000001A',
    color: 'white',
    backgroundColor: '#333399BF !important'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px 20px 0 20px',
    color: '#000000BF',
    fontSize: '18px',
    fontWeight: 600
  },
  searchbar: {
    padding: '10px 20px 20px 20px',
    borderBottom: '1px solid #0000001A'
  }
}))

const colors = [
  '#9933FF',
  '#B265FD',
  '#CC99FE'
]

function Item (props: any) {
  const classes = useStyles()
  const object = props.object
  const avatarColors = props.selected ? {
    background: 'white',
    color: '#333399BF'
  } : {
    background: colors[props.index % 3]
  }
  let status = (<></>)

  if (object.objectType === 'email') {
    status = (<></>)
  } else if (object.isActive === true) {
    status = (<div className={classes.itemActive}>Active</div>)
  } else if (object.isActive === false) {
    status = (<div className={classes.itemInactive}>Inactive</div>)
  }
  return (
    <div className={classes.itemCard}>
      <Avatar
        variant='rounded'
        name={object.name}
        {...avatarColors}
      />
      <div>
        <div className={classes.itemName}>
          {truncateString(object.name, 20, true)}
          {status}
        </div>
        <div className={classes.subtitle}>
          {truncateString(object.subtitle, 33, true)}
        </div>
        <div className={classes.subtitle}>
          {`Last Edited: ${object.lastEdited}`}
        </div>
      </div>
    </div>
  )
}

function ListHeader (props: any) {
  const classes = useStyles()
  const initialValues = props.state.filterCriteria ? { [props.state.filterCriteria.field]: props.state.filterCriteria.value } : {}

  return (
    <div>
      <div className={classes.header}>
        <div style={{ display: 'flex', alignItems: 'center' }}>{props.title}: {props.count}</div>
        <div style={{ display: 'flex' }}>
          {props.filterOptions &&
            <FilterButton
              iconOnly
              listFilterProps={{
                filterOptions: props.filterOptions,
                handleFilterChange: props.handleFilterChange,
                initialValues: initialValues,
                clearAllFilters: props.clearAllFilters,
                clearFilter: props.clearFilter
              }}
              placement='right-start'
            />}
          <SortButton
            sortCriteria={props.state.sortCriteria}
            sortAscending={props.state.sortAscending}
            sort={(value: string) => props.dispatch({ type: 'sort', value })}
            sortFields={props.sortFields}
            iconOnly={props.filterOptions}
          />
        </div>
      </div>
      <div className={classes.searchbar}>
        <SearchBar
          search={props.state.search}
          setSearch={(s: string) => props.dispatch({ type: 'search', value: s })}
          variant='gray'
        />
      </div>
    </div>
  )
}

interface CardListProps {
  title: string | undefined
  list: Array<any>
  select: (objectID: number) => void
  selectedID: number | null
  history?: any
  warning?: string
  isLoading?: boolean
  filterOptions?: Object
}

interface CardListState {
  sortAscending: boolean
  sortCriteria: string
  search: string
  filterCriteria?: Object | null
  clearAllFilters?: () => void
  clearFilter?: (filter: string) => void
}

const getInitialState = (fieldNames: string[], sortColumn: string | undefined, sortAscending: boolean, filterCriteria: Object | undefined): CardListState => {
  const sortCriteria = sortColumn || fieldNames[0]
  return {
    sortAscending: sortAscending,
    sortCriteria: sortCriteria,
    filterCriteria: filterCriteria,
    search: ''
  }
}

const reducer = (state: CardListState, action: { type: string, value?: any }): CardListState => {
  switch (action.type) {
    case 'sort':
      return {
        ...state,
        sortAscending: action.value === state.sortCriteria ? !state.sortAscending : state.sortAscending,
        sortCriteria: action.value
      }
    case 'filter':
      return {
        ...state,
        filterCriteria: action.value
      }
    case 'search':
      return {
        ...state,
        search: action.value
      }
    default:
      break
  }
  return state
}

export function CardList ({ title, list, select, selectedID, history, isLoading, filterOptions }: CardListProps) {
  const classes = useStyles()
  const { setDeleteObject } = useContext(ApiContext)
  // const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const sortFields = [{ name: 'name', label: 'Name' }, { name: 'createdTimestamp', label: 'Created Date' }, { name: 'updatedTimestamp', label: 'Last Edited' }, { name: 'isActive', label: 'Active' }]
  const [state, dispatch] = useReducer(reducer, getInitialState(['name', 'createdTimestamp', 'updatedTimestamp', 'isActive'], 'isActive', false, undefined))
  // const deleteFunc = useRef<any>(null)
  const currentList = getFilteredRows(list, state)

  const handleFilterChange = (newFilterCriteria: any) => {
    dispatch({ type: 'filter', value: newFilterCriteria })
  }
  const clearAllFilters = () => {
    dispatch({ type: 'filter', value: null })
  }
  const clearFilter = (filter: string) => {
    if (state.filterCriteria && state.filterCriteria.field === filter) {
      dispatch({ type: 'filter', value: null })
    }
  }

  if (isLoading) {
    return (
      <div>
        <ListHeader
          title={title}
          state={state}
          dispatch={dispatch}
          sortFields={sortFields}
          count={<div style={{ marginLeft: 5 }}><SkeletonLoader type='number' size='sm' /></div>}
          filterOptions={filterOptions}
        />
        <Divider />
        <ScrollBox style={{ height: 'calc(100vh - 215px)' }}>
          <List className={classes.list}>
            {[...Array(7)].map((_, index) => {
              return (
                <ListItem
                  key={index}
                  className={classes.listItem}
                >
                  <div style={{ paddingLeft: 8, paddingRight: 8, paddingTop: 12, paddingBottom: 12 }}>
                    <SkeletonLoader
                      type='avatarNameIcon&Subtitle'
                      size='md'
                      variant='rect'
                    />
                  </div>
                </ListItem>
              )
            }
            )}
          </List>
        </ScrollBox>
      </div>
    )
  }

  return (
    <div>
      <ListHeader
        title={title}
        state={state}
        dispatch={dispatch}
        sortFields={sortFields}
        count={list.length}
        filterOptions={filterOptions}
        handleFilterChange={handleFilterChange}
        clearAllFilters={clearAllFilters}
        clearFilter={clearFilter}
      />
      <ScrollBox style={{ height: 'calc(100vh - 215px)' }}>
        <List className={classes.list}>
          {currentList.map((object: any, index: number) => {
            const actions: Actions<any> = [
              {
                type: 'edit',
                action: () => {
                  if (history) {
                    history.push(object.link)
                  } else {
                    window.open('#' + object.link, '_self')
                  }
                }
              },
              {
                type: 'delete',
                action: () => {
                  setDeleteObject(object)
                  // setDeleteModalOpen(true)
                }
              }
            ]
            if (typeof object.clone === 'function') {
              actions.push({
                type: 'clone',
                action: () => {
                  object.clone().then((link: string) => {
                    if (history) {
                      history.push(link)
                    } else {
                      window.open('#' + link, '_self')
                    }
                  })
                }
              })
            }
            return (
              <ListItem
                key={index}
                className={classes.listItem}
                classes={{ selected: classes.listItemSelected }}
                onClick={() => select(object.id)}
                selected={selectedID === object.id}
                button
              >
                <Item
                  index={index}
                  object={object}
                  selected={selectedID === object.id}
                />
                <div style={{ marginLeft: 'auto' }}>
                  <ActionsComponent
                    actions={actions}
                    row={object}
                  />
                </div>
              </ListItem>
            )
          })}
        </List>
      </ScrollBox>
      {/* <Modal
        open={deleteModalOpen}
        title='Delete'
        onHide={() => setDeleteModalOpen(false)}
        handleSave={() => {
          if (deleteFunc.current.delete) {
            deleteFunc.current.delete()
          }
          setDeleteModalOpen(false)
        }}
        saveBtnText='Delete'
        saveBtnColor={theme.palette.error.main}
        saveIcon='delete'
        size='xs'
      >
        <ModalSection>
          <Typography variant='body1'>
            {warning || 'Are you sure you want to delete this item?'}
          </Typography>
        </ModalSection>
      </Modal> */}
    </div>
  )
}
