export const getSearchParam = (searchString: string, param: string) => {
  const resp = new URLSearchParams(searchString).get(param) || ''

  return resp
}

export const capitalizeString = (value: string) => {
  return value?.charAt(0).toUpperCase() + value?.slice(1) || ''
}

export const abbreviateName = (name: string) => {
  const firstName = name.split(' ')[0]
  const lastName = name.split(' ')[1]
  return firstName + ' ' + lastName[0] + '.'
}

export const removeHtmlTags = (html: string): string => {
  const parsedHtml = new DOMParser().parseFromString(html, 'text/html')
  const text = parsedHtml.body.textContent || ''
  return text
}
