import { useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Avatar } from 'cf-components/Avatars'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import BusinessIcon from '@material-ui/icons/Business'
import CloseIcon from '@material-ui/icons/Close'
import ActionsMenu from 'cf-components/ActionsMenu'
import { changeConversationStatus, deleteConversation } from 'api/conversations'
import { ConversationWindowContext } from './conversation-window-context'
import DeleteIcon from '@material-ui/icons/DeleteForever'
import { HiddenLink } from 'cf-components/Link'
import DeleteModal from 'components/DeleteModal'
import { truncateString } from 'library/helpers'

const useStyles = makeStyles(theme => ({
  visitorHeader: {
    padding: '10px 15px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  contactInfo: {
    display: 'grid',
    gridTemplateColumns: '60px auto',
    gridGap: 10,
    alignItems: 'center'
  },
  icons: {
    color: '#4A4A4A',
    fontSize: '1.1em',
    marginRight: 10
  },
  detail: {
    fontWeight: 500,
    fontSize: '1em',
    color: '#7C7C7C',
    marginTop: '.2em',
    display: 'flex'
  },
  contactName: {
    fontWeight: 600,
    fontSize: '1.2em',
    color: '#5A5A5A',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  details: {
    overflowX: 'hidden'
  }
}))

function Location (props) {
  const classes = useStyles()
  return (
    <div className={classes.detail}>
      <LocationOnIcon className={classes.icons} />
      {props.location}
    </div>
  )
}

function Company (props) {
  const classes = useStyles()

  if (!props.companyName) {
    return <></>
  }

  let style = {}
  if (props.website) {
    style = {
      cursor: 'pointer',
      textDecoration: 'underline',
      color: '#0000EE'
    }
  }

  const handleClick = () => {
    let link = props.website
    if (link) {
      if (!link.startsWith('http')) {
        link = 'http://' + link
      }
      window.open(link, '_blank')
    }
  }

  return (
    <div
      className={classes.detail}
      style={style}
      onClick={handleClick}
    >
      <BusinessIcon className={classes.icons} />
      {truncateString(props.companyName, 30, true, true)}
    </div>
  )
}

function VisitorHeader (props) {
  const classes = useStyles()
  const { deleteFromList, isOnline } = useContext(ConversationWindowContext)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const conversation = props.conversation
  const conversationID = conversation.id
  const attributes = conversation.attributes
  const online = isOnline(conversation)
  const name = attributes.name || attributes.email || 'Site Visitor'
  const companyName = attributes.company_name || attributes.domain
  const location = attributes.location || 'Unknown'
  const contactID = conversation?.relationships.for_contact.data.id
  const linkToAccountPage = attributes.domain ? window.location.origin + '/#/accounts/' + attributes.domain : null

  const confirmDelete = () => {
    deleteConversation({ conversationID })
      .then(response => {
        setDeleteModalOpen(false)
        deleteFromList(conversationID)
        props.refreshConversation()
      })
  }

  const closeConversation = () => {
    changeConversationStatus({ status: 'closed', conversationID })
      .then(response => props.refreshConversation())
  }

  const isClosed = attributes.status === 'closed'

  /** @type {import('cf-components/ActionsMenu').ActionsMenuAction[]} */
  const actions = [
    { name: isClosed ? 'Conversation Closed' : 'Close Conversation', action: closeConversation, icon: CloseIcon, disabled: isClosed },
    { name: 'Delete Conversation', action: () => setDeleteModalOpen(true), icon: DeleteIcon }
  ]

  const avatar = (conversation, online, contactID) => {
    const avatarIcon = (conversation, online) => {
      return (
        <Avatar
          conversation={conversation}
          status={online ? 'available' : 'unavailable'}
          size='lg'
          useLogos
        />
      )
    }

    if (contactID) {
      return (
        <HiddenLink to={'/contacts/' + contactID}>
          {avatarIcon(conversation, online)}
        </HiddenLink>
      )
    } else {
      return avatarIcon(conversation, online)
    }
  }

  return (
    <div className={classes.visitorHeader}>
      <div className={classes.contactInfo}>
        {avatar(conversation, online, contactID)}
        <div className={classes.details}>
          <div className={classes.contactName}>
            {name}
          </div>
          <Company
            website={linkToAccountPage}
            companyName={companyName}
          />
          <Location location={location} />
        </div>
      </div>
      <ActionsMenu
        actions={actions}
      />
      <DeleteModal
        open={deleteModalOpen}
        onHide={() => setDeleteModalOpen(false)}
        deleteObject={confirmDelete}
        object='conversation'
        title='Delete Conversation'
      />
    </div>
  )
}

export default VisitorHeader
