import React, { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import Extension from '@material-ui/icons/Extension'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import Snackbar from '@material-ui/core/Snackbar'
import { parse } from 'query-string'
import Card from '@material-ui/core/Card'
import { CardHeader, CardContent, Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import EloquaLogo from 'img/eloqua_logo.png'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import TextField from '@material-ui/core/TextField'
import SensitiveTextField from 'components/SensitiveTextField'
import { getCredentialsStatus, INTEGRATIONS, setCredentials } from 'api/integrations'
import clsx from 'clsx'
import Collapse from '@material-ui/core/Collapse'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import ErrorOutlinedIcon from '@material-ui/icons/ErrorOutlined'
import { Icon } from 'library/materialUI'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  card: {
    width: 350,
    height: 375,
    marginTop: 30,
    borderRadius: 10
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  logo: {
    height: 50
  },
  logoWrapper: {
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 25
  },
  button: {
    position: 'absolute',
    bottom: 40,
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%'
  },
  cardHeader: {
    widht: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: 10
  },
  headerTitle: {
    paddingLeft: 20,
    fontSize: 24,
    fontWeight: 'bold',
    paddingTop: 10
  },
  connectedIcon: {
    color: 'rgba(0, 135, 7, 0.7)'
  },
  disconnectedIcon: {
    color: 'rgba(255, 188, 3)'
  },
  cardContent: {
    height: '100%',
    position: 'relative',
    margin: 20
  },
  description: {
    textAlign: 'center',
    fontSize: 15,
    marginTop: 25
  },
  subtitle2: {
    fontSize: 15,
    fontWeight: 600,
    marginBottom: 10
  },
  textField: {
    cursor: 'text',
    width: '100%',
    marginBottom: 25,
    marginRight: 10
  },
  groupContainer: {
    backgroundColor: 'rgba(193, 166, 237, 0.1)',
    padding: 30,
    borderRadius: 10,
    marginBottom: 20
  },
  oAuthContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: 30,
    borderRadius: 10
  },
  expandingCard: {
    backgroundColor: 'rgba(193, 166, 237, 0.1)',
    borderRadius: 10,
    marginBottom: 20
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  }
}))

const initialValues = {
  type: 'integrations',
  attributes: {
    client_id: '',
    client_secret: ''
  }
}

const oauth_mappings = {
  access_denied: 'Oauth Error: access was denied. Please try again.',
  immediate_unsuccessful: 'Oauth Error: please try again.'
}

function oauth_message (input_message) {
  const attempt = oauth_mappings[input_message]
  if (!input_message) {
    return ''
  }
  if (!attempt) {
    return 'Unknown error. Please try again'
  }
  return attempt
}

const EloquaOAuth = (props) => {
  const classes = useStyles()
  const query_string = parse(window.location.search)
  const eloqua_oauth_error = oauth_message(query_string.error)
  const [startURL, setStartURL] = useState(null)
  const [oauthMessage, setOauthMessage] = useState(eloqua_oauth_error)
  const [clientInfo, setClientInfo] = useState(initialValues)
  const [expanded, setExpanded] = useState(false)

  useEffect(() => {
    getCredentialsStatus(INTEGRATIONS.Eloqua).then(response => {
      const clientID = response?.data?.attributes?.client_id
      setStartURL(response?.data?.attributes?.oauth_start_url)
      if (clientID) {
        setClientInfo({
          attributes: {
            client_id: clientID,
            client_secret: ''
          }
        })
      }
    })
  }, [])

  function saveSettings (event) {
    setCredentials(INTEGRATIONS.Eloqua, [{
      attributes: {
        name: 'client_id',
        value: clientInfo.attributes.client_id,
        secret: false
      }
    },
    {
      attributes: {
        name: 'client_secret',
        value: clientInfo.attributes.client_secret,
        secret: true
      }
    }]).then(() => {
      getCredentialsStatus(INTEGRATIONS.Eloqua).then(response => {
        setStartURL(response?.data?.attributes?.oauth_start_url)
      })
    })
  }

  function performOauth () {
    if (startURL) {
      window.location.href = startURL
    }
  }

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const handleEdit = ({ type, data }) => {
    data = data.trim()
    setClientInfo({
      ...clientInfo,
      attributes: {
        ...clientInfo.attributes,
        [type]: data
      }
    })
  }

  function onMessageClose (event) {
    setOauthMessage(false)
  }

  return (
    <div>
      <Card className={classes.expandingCard}>
        <div onClick={handleExpandClick} className={classes.cardHeader}>
          <div>
            <Typography variant='subtitle1' className={classes.headerTitle}>
              Authentication
            </Typography>
          </div>
          <div>
            {clientInfo.attributes.client_id && (startURL || clientInfo.attributes.client_secret)
              ? (
                <Tooltip title='Ready to launch authorization app' placement='left'>
                  <IconButton disableRipple>
                    <CheckCircleOutlineIcon
                      fontSize='large'
                      className={classes.connectedIcon}
                    />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title='Missing Client ID or Client Secret' placement='left'>
                  <IconButton disableRipple>
                    <ErrorOutlinedIcon
                      fontSize='large'
                      className={classes.disconnectedIcon}
                    />
                  </IconButton>
                </Tooltip>
              )}
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded
              })}
              aria-expanded={expanded}
              aria-label='show more'
            >
              <ExpandMoreIcon />
            </IconButton>
          </div>
        </div>
        <Collapse in={expanded} timeout='auto' unmountOnExit>
          <CardContent>
            <div className={classes.container}>
              <div className={classes.groupContainer}>
                <Typography variant='subtitle1'>
                  Enter your Eloqua Client ID provided by your Eloqua CRM <br />
                  Make sure the Client ID is correct as it will be used to launch your Eloqua authorization agent
                </Typography>
                <Typography variant='subtitle2' className={classes.subtitle2}>
                  Client ID:
                </Typography>
                <TextField
                  className={classes.textField}
                  color='primary'
                  variant='outlined'
                  value={clientInfo.attributes.client_id}
                  autoComplete='off'
                  onChange={(e) => {
                    handleEdit({ type: 'client_id', data: e.target.value })
                  }}
                />
                <Typography variant='subtitle1'>
                  Enter your Eloqua Client Secret provided by your Eloqua CRM <br />
                  Make sure the Client Secret is correct as it will be used to refresh your authorization
                </Typography>
                <Typography variant='subtitle2' className={classes.subtitle2}>
                  Client Secret:
                </Typography>
                <SensitiveTextField
                  className={classes.textField}
                  color='primary'
                  variant='outlined'
                  autoComplete='new-password'
                  value={clientInfo.attributes.client_secret}
                  onChange={(e) => {
                    handleEdit({ type: 'client_secret', data: e.target.value })
                  }}
                />
                <Button
                  variant='outlined'
                  color='primary'
                  onClick={saveSettings}
                  disabled={!clientInfo.attributes.client_id || !clientInfo.attributes.client_secret}
                >
                  Save
                </Button>
              </div>
            </div>
          </CardContent>
        </Collapse>
      </Card>
      <div className={classes.oAuthContainer}>
        <Card className={classes.card} raised>
          <div className={classes.cardContent}>
            <div className={classes.logoContainer}>
              <div className={classes.logoWrapper}>
                <div className={classes.logo} style={{ marginRight: '10px' }}>
                  <Icon icon='logo' />
                </div>
                <img src={EloquaLogo} className={classes.logo} alt='Eloqua Logo' />
              </div>
            </div>
            <CardHeader title={props.name} style={{ textAlign: 'center' }}>
              <Extension />
            </CardHeader>
            <Divider />
            <div>
              <div>
                <Typography variant='body2' className={classes.description}>
                  You are one step away from linking your Signals account
                  with Eloqua CRM!
                </Typography>
                <Button
                  variant='outlined'
                  color='primary'
                  className={classes.button}
                  onClick={performOauth}
                  disabled={!startURL}
                >
                  Start activation
                </Button>
              </div>

            </div>
          </div>
        </Card>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        aria-describedby='client-snackbar'
        action={[
          <IconButton
            key='close'
            aria-label='Close'
            color='inherit'
            onClick={onMessageClose}
          >
            <CloseIcon className={classes.icon} />
          </IconButton>
        ]}
        open={Boolean(oauthMessage)}
        autoHideDuration={6000}
        ContentProps={{
          'aria-describedby': 'message-id'
        }}
        message={<span id='message-id'>{eloqua_oauth_error}</span>}
      />
    </div>
  )
}

export default EloquaOAuth
