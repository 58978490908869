import TreeMultiSelect, { TreeChild, TreeMultiSelectStyle } from 'cf-components/TreeMultiSelect'
import { useEffect, useState } from 'react'
import SearchBar from 'components/SearchBar'
// import MultiSelectTree from 'cf-components/MultiSelectTree'
import { SelectorType } from '../DashboardHelper'
import Loader from 'library/loading/Loader'
import RangeSelect from './RangeSelect'
import DateSelect from './DateSelect'

const treeItemStyles: TreeMultiSelectStyle = {
  fontSize: '.9rem',
  padding: '4px'
}

interface DashboardFilterSelectProps {
  filterType: SelectorType
  fieldType: string
  selected: string[]
  setSelected: (values: any[]) => void
  setExcluded: (values: any[]) => void
  excluded: string[]
  items: TreeChild[]
  loadingItems: boolean
  search: string
  setSearch: (search: string) => void
}

export function getSelectorType (type: string) {
  switch (type) {
    case 'revenue_filter':
    case 'employee_filter':
    case 'engagement_filter':
    case 'total_visits_filter':
    case 'contacts_filter':
      return 'range'
    case 'location_filter':
      return 'select'
    case 'recent_visits_filter':
      return 'calendar'
  }
  return 'select'
}

function searchCreateTree (items: any[], search: string): any[] {
  if (search === '') return items
  const newItems = []
  for (let i = 0; i < items.length; i++) {
    if (items[i].children) {
      const newChildren = searchCreateTree(items[i].children, search)
      if (newChildren.length > 0) {
        newItems.push({ ...items[i], children: newChildren })
      } else {
        if (items[i].value.toLowerCase().includes(search.toLowerCase())) {
          newItems.push({ ...items[i], children: [] })
        }
      }
    } else if (items[i].label) {
      if (items[i].label.toLowerCase().includes(search.toLowerCase())) {
        newItems.push(items[i])
      }
    } else {
      if (items[i].value.toLowerCase().includes(search.toLowerCase())) {
        newItems.push(items[i])
      }
    }
  }
  return newItems
}

export default function DashboardFilterSelect (
  {
    filterType, fieldType,
    selected, setSelected,
    setExcluded, excluded,
    items, loadingItems,
    search, setSearch
  }: DashboardFilterSelectProps) {
  const [filteredItems, setFilteredItems] = useState<any>([])

  const [showSearch] = useState(items.length > 0)

  useEffect(() => {
    if (!loadingItems && items.length > 0) {
      setFilteredItems(searchCreateTree(items, search))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingItems, search, items])

  if (loadingItems) {
    return <Loader type='spinner' />
  }

  return (
    <div>
      {filterType === 'select' ? (
        <div>
          {(showSearch) &&
            (
              <div style={{ display: 'flex', width: '90%', marginRight: 'auto', marginLeft: 'auto', marginBottom: 20 }}>
                <SearchBar
                  search={search}
                  setSearch={setSearch}
                  variant='outlined'
                  placeholder='Type to search...'
                />
              </div>
            )}
          <TreeMultiSelect
            items={filteredItems}
            selected={selected}
            setSelected={setSelected}
            setExcluded={setExcluded}
            excluded={excluded}
            styles={treeItemStyles}
            selectAll={fieldType === 'location' || fieldType === 'high_intent_page_ids'}
            expandAll={(search !== '')}
            truncateLength={30}
          />
        </div>
      ) : filterType === 'range' ? (
        <RangeSelect
          fieldType={fieldType}
          setSelected={setSelected}
          selected={selected}
        />
      ) : filterType === 'calendar' && (
        <DateSelect
          setSelected={setSelected}
          selected={selected}
        />
      )}
    </div>
  )
}
