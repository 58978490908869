import Button from '@material-ui/core/Button'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import LaunchIcon from '@material-ui/icons/Launch'
import { assistUser, notificationsExport } from 'api/admin_only/tenants'
import { Actions, Fields, Table } from 'cf-components/table/Table'
import User from 'classes/users'
import { downloadCSV } from 'components/downloadCSV'
import dateFormat from 'dateformat'
import Loader from 'library/loading/Loader'
import { useRef, useState } from 'react'

const useStyles = makeStyles(theme => ({
  container: {
    padding: 20,
    borderRadius: 10
  },
  header: {
    padding: '5px 10px',
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '3fr 50px 2fr 90px 2fr 1fr 50px',
    gridGap: 10
  }
}))

export interface roleTypes {
  '1': string
  '2': string
  '3': string
  '4': string
  '5': string
  '6': string
  '7': string
  '8': string
  '9': string
}

export const roleMap: roleTypes = {
  1: 'Admin',
  2: 'Marketer',
  3: 'SDR',
  4: 'Account Executive',
  5: 'Customer Service Rep',
  6: 'Sales Manager',
  7: 'Customer Support Manager',
  8: 'Calendar User',
  9: 'Admin No Chat'
}

export const AdminUserListPage = (props: { users: any[] }) => {
  const classes = useStyles()
  const users = props.users
  const [assistPopperOpen, setAssistPopperOpen] = useState(false)
  const anchorElRef = useRef(null)
  const [assistLink, setAssistLink] = useState<any>()
  const [userSelect, setUserSelect] = useState('')
  const [linkLoading, setLinkLoading] = useState(false)

  function beginExport () {
    notificationsExport().then(response => {
      const date = new Date().toString()
      const filename = 'Notifications_Export_' + dateFormat(date, 'isoDate') + '.csv'
      downloadCSV(filename, response)
    })
  }

  const fields: Fields<User> = [
    { name: 'name', type: 'avatar', label: 'Name', link: '_blank' },
    { name: 'id', type: 'text', label: 'Id' },
    { name: 'createdDate', type: 'date', label: 'Created Date' },
    { name: 'totalLogins', type: 'text', label: 'Logins' },
    { name: 'lastSessionActivityDate', type: 'date', label: 'Last Activity' },
    { name: 'role', type: 'text', label: 'Role' }
  ]

  const actions: Actions<User> | undefined = [
    {
      name: 'Assist Login',
      icon: LaunchIcon,
      action: (row: User) => {
        setLinkLoading(true)
        setUserSelect(row.name)
        assistUser(row.tenantID, row.id).then((response: any) => {
          setLinkLoading(false)
          setAssistLink(response.meta.assist_url)
          setAssistPopperOpen(true)
        })
      }
    }
  ]

  return (
    <div className={classes.container}>
      <Table
        rows={users}
        fields={fields}
        columnStyles='3fr 1fr 2fr 1fr 2fr 1fr 50px'
        actions={actions}
        handleExport={beginExport}
        actionPopRef={anchorElRef}
      />
      <Popper
        open={assistPopperOpen || linkLoading}
        anchorEl={anchorElRef.current}
      >
        <ClickAwayListener onClickAway={() => setAssistPopperOpen(false)}>
          <Paper style={{ padding: '10px', margin: '10px', width: '350px' }}>
            {linkLoading ? (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                <Loader type='spinner' />
              </div>
            ) : (
              <>
                <Typography variant='h6'>Assist User: {userSelect}</Typography>
                <Typography variant='body1'>To properly use the Signals Assist feature, copy the link below and paste it into a <em>brand new</em> Incognito window. This is important as otherwise it will log you out and may cause unexpected behavior.</Typography>
                <div style={{ width: '250px', overflow: 'hidden', textOverflow: 'ellipsis', margin: '10px' }}>{assistLink}</div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Button
                    variant='contained'
                    onClick={() => {
                      const data = [new window.ClipboardItem({ 'text/plain': new Blob([assistLink], { type: 'text/plain' }) })]
                      navigator.clipboard.write(data).then(function () {
                        setAssistPopperOpen(false)
                      }, function () {
                        console.error('Unable to write to clipboard. :-(')
                      })
                    }}
                  >Copy
                  </Button>
                </div>
              </>
            )}
          </Paper>
        </ClickAwayListener>
      </Popper>
    </div>
  )
}
