import { Typography } from 'library/materialUI';
import { Modal } from 'library/Modal';

type SnapshotUpgradePromptModalProps = {
  open: boolean
  onClose: () => void
  children?: React.ReactNode
}

export const ipLookupUpgradeMessage = (
  <>
    <div>
      <Typography variant='h1'>
        <strong>Identify More Visitors with the Insights Plan</strong>
      </Typography>
      <br />
      <Typography variant='body1'>
        Upgrade to Insights for $99 and get <strong>300 additional lookups</strong> to see who's visiting your site.
        Gain deeper insights and make more informed decisions—upgrade now and start identifying more of your traffic today.
      </Typography>
    </div>
  </>
)

export const contactUpgradeMessage = (
  <>
    <div>
      <Typography variant='h1'>
        <strong>Upgrade to Insights for Contact Lookups</strong>
      </Typography>
      <br />
      <Typography variant='body1'>
        Snapshot doesn’t include contact lookups. To identify buyer personas and build highly qualified lead lists,
        upgrade to Insights for just $99. Unlock data, and connect with the right contacts to drive better results for your business.
      </Typography>
    </div>
  </>
)

export const usersUpgradeMessage = (
  <>
    <div>
      <Typography variant='h1'>
        <strong>Upgrade to Insights for $99</strong>
      </Typography>
      <br />
      <Typography variant='body1'>
        Add 2 additional users to your account and unlock contact lookups. Identify buyer personas and build high-quality lead
        lists—empowering your team to engage more prospects and drive better results. Upgrade now and get more out of your data.
      </Typography>
    </div>
  </>
)

export const icpUpgradeMessage = (
  <>
    <div>
      <Typography variant='h1'>
        <strong>Upgrade to Insights and Configure your ICP</strong>
      </Typography>
      <br />
      <Typography variant='body1'>
        Configure your Ideal Customer Profile (ICP) with Insights. Identify and connect with your best-fit customers to drive
        better results for your business.
      </Typography>
    </div>
  </>
)

export default function SnapshotUpgradePromptModal ({ open, onClose, children }: SnapshotUpgradePromptModalProps): JSX.Element {
    return (
      <Modal
        title='Upgrade plan'
        size='md'
        saveBtnText='Schedule a meeting to upgrade'
        open={open}
        onHide={onClose}
        handleSave={() => {
          window.open('https://getsignals.ai/insights-plan', '_blank')
          // const searchParams = new URLSearchParams(window.location.hash.split('?')[1] || window.location.search)
          // searchParams.set('snapshot_upgrade', 'true')
          // const currentHash = window.location.hash.split('?')[0]
          // window.history.pushState({}, '', `${window.location.origin}${window.location.pathname}${currentHash}?${searchParams.toString()}`)
          // onClose()
          // window.dispatchEvent(new CustomEvent('queryParamChange'))
        }}
      >
        {children}
      </Modal>
    )
}
