import { makeStyles } from '@material-ui/core/styles'
import { PartialObject, RenderInput, RenderOption } from 'cf-components/Pickers';
import { defaultSearchParams, LoadAllProps, SearchParams } from 'classes/queryHelpers';
import { Technologies } from 'classes/technologies';
import { TLD } from 'classes/topLevelDomains';
import Autocomplete from '@material-ui/lab/Autocomplete'
import MenuItem from '@material-ui/core/MenuItem';
import CancelIcon from '@material-ui/icons/Cancel'
import Loader from 'library/loading/Loader';
import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { debounceDelay } from 'library/constants'
import { cloneDeep } from 'lodash'
import Select from '@material-ui/core/Select'
import { Checkbox, Chip, InputAdornment, ListItemText, ListSubheader, TextField, Typography } from '@material-ui/core';
import { Button, Icon } from 'library/materialUI';
import { toTitleCase, truncateString } from 'library/helpers'
import { Industry } from 'classes/industries';

const useStyles = makeStyles(theme => ({
  multipleSelect: {
    display: 'flex',
    maxWidth: '100%',
    width: '100%',
    '&.range': {
      padding: 8,
      paddingBottom: 24,
      width: 'calc(100% - 24px)',
      '& .cf-padded-tip': {
        width: 'max-content',
        height: 20,
        position: 'relative',
        '&.cf-padded-tip-hidden': { display: 'none' },
        '& > .cf-padded-tip-content': {
          position: 'relative',
          '& > .cf-padded-tip-inner': {
            background: '#808080',
            color: '#FFFFFF',
            borderRadius: 4,
            padding: 3,
            fontSize: '0.9em'
          }
        }
      }
    },
    '& > div': {
      width: '100%',
      '& > *': { width: '100%' }
    }
  },
  disabled: {
    opacity: 0.5
  },
  searchBar: {
    marginTop: 5
  },
  selectedFields: {
    display: 'flex',
    width: 600,
    flexWrap: 'wrap',
    marginLeft: 20,
    maxHeight: 100,
    overflowY: 'auto'
  },
  subHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginLeft: 25
  },
  clearButton: {
    justifyContent: 'flex-end',
    marginRight: 10
  },
  chips: {
    margin: 5
  }
}))

function getMultipleProps (
  selection: SelectionType,
  setSelection: SetSelectionType,
  pickerLoadAllProps: LoadAllProps | undefined,
  setPickerLoadAllProps: Dispatch<SetStateAction<LoadAllProps | undefined>>,
  setInputValue: Dispatch<SetStateAction<string>>,
  items: ItemsType,
  placeholder?: string
) {
  const currentSelection = selection || []
  const currentPlaceholder = currentSelection.length > 0 ? undefined : placeholder
  const renderOption = (option: PartialObject) => (
    <RenderOption
      option={option}
      checked={currentSelection.includes(option.id)}
      multiple
    />)
  const onChange = (event: ChangeEvent<{}>, value: (PartialObject | string)[]) => {
    if (typeof value[0] !== 'string') {
      const currentIDs = value.map((o: any) => o.id)
      setSelection(currentIDs)
    } else if (typeof value[0] === 'string') {
      setSelection(value)
    } else if (!value[0]) {
      setSelection([])
    }
  }
  const onInputChange = (event: ChangeEvent<{}>, value: string) => {
    const loadAllPropsExists = pickerLoadAllProps && pickerLoadAllProps.searchParams
    const newLoadAllProps = loadAllPropsExists ? { ...pickerLoadAllProps } : { searchParams: cloneDeep(defaultSearchParams) }
    const valueAlreadySet = loadAllPropsExists ? (pickerLoadAllProps?.searchParams as SearchParams).search === value : false

    if (!valueAlreadySet) {
      (newLoadAllProps.searchParams as SearchParams).search = value
      setPickerLoadAllProps(newLoadAllProps)
    }
    setInputValue(value)
  }
  return { currentSelection, currentPlaceholder, renderOption, onChange, onInputChange }
}

const mapping = {
  industries: Industry,
  technologies: Technologies,
  tld: TLD
}

type SelectionType = string[] | undefined
type ItemsType = PartialObject[] | []
type SetSelectionType = (value: SelectionType) => void
interface BackendPickerProps {
  objectType: keyof typeof mapping
  loadAllProps?: LoadAllProps
  placeholder?: string
  selection: SelectionType
  setSelection: SetSelectionType
  label?: string
  isSelect?: boolean
  title?: string
  truncateLength?: number
}

export default function BackendPicker ({ objectType, loadAllProps, selection, placeholder, setSelection, label, isSelect, title, truncateLength }: BackendPickerProps) {
  const classes = useStyles()
  const object = mapping[objectType]
  const [pickerLoadAllProps, setPickerLoadAllProps] = useState(loadAllProps)
  const [debouncedProps] = useDebounce(cloneDeep(pickerLoadAllProps), debounceDelay)
  const { data, isLoading } = object.loadAll(debouncedProps)
  const [inputValue, setInputValue] = useState('')
  const items: ItemsType = data?.list || []
  if (isLoading && !(items.length > 0) && !inputValue && !selection?.length) {
    return (
      <Loader type='dropdown' />
    )
  }
  const {
    currentPlaceholder,
    renderOption,
    onChange,
    onInputChange
  } = getMultipleProps(
    selection,
    setSelection,
    pickerLoadAllProps,
    setPickerLoadAllProps,
    setInputValue,
    items,
    placeholder
  )

  if (isSelect) {
    return (
      <div className={classes.multipleSelect}>
        <div>
          <Select
            value={selection || []}
            onChange={(e: any) => {
              if (!['pickerSearch', 'pickerSubHeader', 'pickerSelectedFields'].includes(e.currentTarget.id)) {
                const selected = e.target.value[0] ? e.target.value : []
                onChange(e, selected)
              }
            }}
            renderValue={(selected: any) => {
              if (selected && (selected as string[]).length) {
                return (
                  truncateLength ? (
                    truncateString((selected as string[]).join(', '), truncateLength, true)
                  ) : (
                    (selected as string[]).join(', ')
                  )
                )
              }
              return <em className={classes.disabled}>{placeholder}</em>
            }}
            multiple
            displayEmpty
            variant='outlined'
            margin='dense'
            MenuProps={{
              variant: 'menu',
              autoFocus: false,
              disableAutoFocusItem: true,
              disableEnforceFocus: true,
              disableAutoFocus: true,
              getContentAnchorEl: null
            }}
          >
            <ListSubheader
              id='pickerSearch'
              style={{ backgroundColor: '#fff' }}
            >
              <TextField
                className={classes.searchBar}
                size='small'
                autoFocus
                placeholder='Type to search...'
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Icon icon='search' color='#975ce6' />
                    </InputAdornment>
                  )
                }}
                onChange={(e: any) => onInputChange(e, e.target.value)}
                onKeyDown={(e) => {
                  if (e.key !== 'Escape') {
                    e.stopPropagation()
                  }
                }}
              />
            </ListSubheader>
            <div className={classes.subHeader} id='pickerSubHeader'>
              <Typography variant='body2' style={{ fontSize: 16 }}><em>{title || 'Searched Fields'}</em></Typography>
              {(selection?.length) ? (
                <div className={classes.clearButton}>
                  <Button
                    variant='text'
                    color='primary'
                    title='Clear All'
                    onClick={() => setSelection([])}
                  >
                    Clear All
                  </Button>
                </div>
              ) : <></>}
            </div>
            <div className={classes.selectedFields} id='pickerSelectedFields'>
              {selection?.map((value: string) => {
                return (
                  <Chip
                    key={value}
                    className={classes.chips}
                    label={toTitleCase(value)}
                    variant='outlined'
                    color='primary'
                    onDelete={() => setSelection(selection.filter((s: string) => s !== value))}
                    deleteIcon={<CancelIcon />}
                  />
                )
              })}
            </div>
            {items?.map((value: PartialObject) => {
              return (
                <MenuItem key={value.id} value={value.id}>
                  <Checkbox
                    color='primary'
                    checked={(selection || []).indexOf(value.id) > -1}
                  />
                  <ListItemText primary={value.name} />
                </MenuItem>
              )
            })}
            {items?.length === 0 && inputValue && (
              <MenuItem disabled value=''>
                {isLoading ? <Loader type='spinner' /> : <em>No results found</em>}
              </MenuItem>)}
          </Select>
        </div>
      </div>
    )
  }

  return (
    <Autocomplete
      options={items || []}
      renderInput={(params) => <RenderInput {...params} label={label} placeholder={currentPlaceholder} objectClass={object} />}
      renderOption={renderOption}
      onChange={onChange}
      inputValue={inputValue}
      onInputChange={onInputChange}
      limitTags={2}
      getOptionLabel={(option) => option.name}
      groupBy={(option) => option.group ? option.group.length >= 28 ? option.group.substring(0, 27) + '...' : option.group : ''}
      onOpen={() => { window.autocompleteOpen = true }}
      size='small'
      multiple
      fullWidth
      disableCloseOnSelect
    />
  )
}
