import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import BaseCard from './BaseCard'
import { ConversationContext } from '../../ConversationContext'
import { Button, Icon, Tooltip, Typography } from 'library/materialUI'
import { AIDirective, deleteTrainingData, getTrainingByConversationId, defaultAIDirective } from 'api/ai_settings'
import { truncateString } from 'library/helpers'
import CoachingModal from 'pages/settings/AIContent/CoachingModal'
import DeleteModal from 'cf-components/DeleteModal'

const useStyles = makeStyles(theme => ({
  lightText: {
    fontSize: '14px',
    color: '#808080'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '10px',
    marginBottom: '10px',
    alignItems: 'center'
  },
  textField: {
    width: '100%',
    marginTop: '20px'
  },
  trainingRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px',
    borderBottom: '1px solid #D3D3D3'
  }
}))

interface AITrainingCardProps {
  conversation: any
  id?: number
}

function CardContents (props: AITrainingCardProps) {
  const classes = useStyles()
  const [dataRows, setDataRows] = useState<AIDirective[]>([])
  const [coachingModalOpen, setCoachingModalOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [deleteID, setDeleteID] = useState(0)
  const [trainingObject, setTrainingObject] = useState<AIDirective>(defaultAIDirective())

  const conversationID = props.conversation.id

  function handleDeleteRow (rowID: number) {
    setDeleteID(rowID)
    setDeleteModalOpen(true)
  }

  function confirmDelete () {
    deleteTrainingData(deleteID).then(() => {
      getData()
      setDeleteModalOpen(false)
    })
  }

  function getData () {
    getTrainingByConversationId(conversationID).then(response => {
      setDataRows(response || [])
    })
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line
  }, [])

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div className={classes.lightText}>
          Give additional coaching about this conversation to make the AI respond better in the future.
        </div>
      </div>

      <div className={classes.buttonContainer}>
        <Button
          variant='outlined'
          onClick={() => {
            const newTrainingObject = defaultAIDirective()
            newTrainingObject.conversation_id = conversationID
            newTrainingObject.type = 'coaching'
            newTrainingObject.bot_id = props?.conversation?.relationships?.bot?.data?.id
            setTrainingObject(newTrainingObject)
            setCoachingModalOpen(true)
          }}
          style={{ textTransform: 'none' }}
        >
          Add Coaching
        </Button>
      </div>
      <div>
        {dataRows.map((row) => (
          <div key={row.id} className={classes.trainingRow}>
            <Typography variant='body2'>{truncateString(row.prompt, 30, true, true)}</Typography>
            <div style={{ display: 'flex' }}>
              <div style={{ cursor: 'pointer' }}>
                <Icon
                  icon='edit'
                  size='md'
                  color='gray'
                  onClick={() => {
                    const editTrainingObject = defaultAIDirective()
                    editTrainingObject.id = row.id
                    setTrainingObject(editTrainingObject)
                    setCoachingModalOpen(true)
                  }}
                />
              </div>
              <div style={{ cursor: 'pointer' }}>
                <Icon
                  icon='delete'
                  size='md'
                  color='gray'
                  onClick={() => handleDeleteRow(row.id)}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
      <CoachingModal
        open={coachingModalOpen}
        setOpen={setCoachingModalOpen}
        trainingData={trainingObject}
        participantID={props?.conversation?.relationships?.for_participant?.data?.id}
        onSave={getData}
      />
      <DeleteModal open={deleteModalOpen} title='Delete Coaching' object='Coaching data' onHide={() => setDeleteModalOpen(false)} confirmAction={confirmDelete} />
    </div>
  )
}

function DetailsCard (props: AITrainingCardProps) {
  const conversation = props.conversation

  return (
    <BaseCard
      title={
        <div style={{ display: 'flex' }}>
          AI Coaching
          <Tooltip title='Powered by Artificial Intelligence'>
            <div>
              <Icon icon='aiSparkles' />
            </div>
          </Tooltip>
        </div>
      }
      id={props.id}
    >
      <CardContents
        conversation={conversation}
      />
    </BaseCard>
  )
}

function AITrainingCard (props: AITrainingCardProps) {
  return (
    <ConversationContext.Consumer>
      {state => (
        <DetailsCard
          conversation={state.conversation}
          id={props.id}
        />
      )}
    </ConversationContext.Consumer>
  )
}

export default AITrainingCard
