import { useReducer } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Modal, ModalSection } from 'library/Modal'
import { TextField } from '@material-ui/core'
import { createBot } from 'api/bots'
import { useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'
import { Bot } from 'classes/classes'
import { saveTrainingData } from 'api/ai_settings'

const useStyles = makeStyles(theme => ({
  instructions: {
    paddingBottom: 15
  },
  valueDiv: {
    width: '60%'
  }
}))

const initialState = {
  name: '',
  description: ''
}

const onboardingInitialState = {
  prompt: ''
}

const reducer = (state, changes) => {
  return { ...state, ...changes }
}

export default function NewBotModal (props) {
  const classes = useStyles()
  const [state, dispatch] = useReducer(reducer, initialState)
  const [onboardingState, onboardingDispatch] = useReducer(reducer, onboardingInitialState)
  const queryClient = useQueryClient()
  const history = useHistory()
  const botDetails = props.botDetails

  const saveBot = () => {
    createBot({
      name: state.name,
      description: state.description,
      kind: botDetails.kind,
      template: botDetails.template
    }).then(response => {
      const bot = new Bot({ row: response.data })
      queryClient.setQueryData(['bots', bot.id], bot)
      history.push(`/bots/${bot.id}`)
      saveTrainingData({
        id: 0,
        bot_id: parseInt(bot.id),
        prompt: onboardingState.prompt,
        type: 'onboarding'
      })
    })
  }

  return (
    <Modal
      open={props.open}
      onHide={props.onHide}
      mode='plain'
      title='New Bot'
      saveBtnText='Create Bot'
      saveIcon='add'
      handleSave={saveBot}
      saveDisabled={!state.name}
    >
      <div>
        <ModalSection
          title='Name'
          subtitle='Give your bot a name. This is for internal use only and will not be seen by web visitors.'
        >
          <div className={classes.valueDiv}>
            <TextField
              margin='dense'
              variant='outlined'
              fullWidth
              onChange={(e) => dispatch({ name: e.target.value })}
              placeholder='Chatbot name'
            />
          </div>
        </ModalSection>
        <ModalSection title='Description'>
          <TextField
            margin='dense'
            multiline
            rows={2}
            variant='outlined'
            fullWidth
            onChange={(e) => dispatch({ description: e.target.value })}
            placeholder='Type a short description here'
          />
        </ModalSection>
        {botDetails?.kind === 'ai' && (
          <ModalSection title='AI Onboarding' subtitle='Give your AI agent a name, role, or description to help the AI learn more about its purpose.'>
            <TextField
              margin='dense'
              multiline
              maxRows={2}
              minRows={2}
              variant='outlined'
              fullWidth
              onChange={(e) => onboardingDispatch({ prompt: e.target.value })}
              placeholder='You are SignalsBot, a customer service AI that...'
            />
          </ModalSection>
        )}
      </div>
    </Modal>
  )
}
