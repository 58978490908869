import { useContext } from 'react'
import { Perms, SessionContext } from '../session-context'
import { ShareContext } from 'share-context'

type AccessControlProps = {
  requiredPermission?: keyof Perms,
  requiredComponent?: string,
  flag?: boolean,
  children: JSX.Element,
  or?: boolean
}

const AccessControl = ({ requiredPermission, requiredComponent, flag, children, or }: AccessControlProps) => {
  const { user, isOn } = useContext(SessionContext)
  const { loadingFlags } = useContext(ShareContext)
  if (loadingFlags || flag === false) {
    return <></>
  }

  let hasPermission = true
  if (requiredPermission) {
    const perms = user?.attributes?.perms || {}
    hasPermission = perms[requiredPermission]
  }

  let hasComponent = true
  if (requiredComponent) {
    hasComponent = !!isOn(requiredComponent)
  }

  const canAccess = or ? (hasPermission || hasComponent) : (hasPermission && hasComponent)
  return canAccess ? children : <></>
}

export default AccessControl
