/* eslint-disable @typescript-eslint/naming-convention */
import { useState, useReducer, useContext, useEffect } from 'react';
import AppPage from 'cf-components/AppPage';
import SettingsCard from 'cf-components/SettingsCard'
import { makeStyles } from '@material-ui/styles';
import InformationIcon from 'library/InformationIcon'
import ConsentFormTable from './ConsentFormTable';
import { TextField, Button, Typography } from '@material-ui/core';
import CustomizedSnackbar, { SnackState } from 'components/CustomizedSnackbar'
import Picker from 'cf-components/Pickers';
import { SessionContext } from 'session-context';
import { isEqual } from 'lodash';
import { gdprDeleteContact, generateParticipantReport } from 'api/participants'
import DateFormat from 'dateformat'

const useStyles = makeStyles((theme) => ({
  dataPrivacyPage: {
    padding: 20
  },
  tableDiv: {
    width: '85%'
  },
  inlineButton: {
    marginLeft: 10,
    minWidth: 125
  },
  informationIcon: {
    marginLeft: 10,
    display: 'flex'
  },
  select: {
    width: '40%'
  },
  titleAndIconDiv: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10
  }
}))

const reducer = (state: any, changes: any): any => {
  return { ...state, ...changes }
}

export default function DataPrivacyPage (): any {
  const classes = useStyles()
  const { chatSettings, updateChatSettings } = useContext(SessionContext)
  const [state, dispatch] = useReducer(reducer, chatSettings || null)
  const [exportContact, setExportContact] = useState('')
  const [deleteContact, setDeleteContact] = useState('')
  const textFieldProps = { variant: 'outlined' as const, size: 'small' as const }
  const buttonProps = { variant: 'contained' as const, color: 'primary' as const, className: classes.inlineButton }
  const [disabled, setDisabled] = useState(true)
  const [snackState, setSnackState] = useState<SnackState>({
    open: false,
    variant: 'success',
    message: 'Snippet copied to clipboard!'
  })
  const errorMsg = (contact: string) => `Uh oh! It looks like we don't have any data for ${contact} on file. You may want to check the spelling and try again`

  if (chatSettings && state === null) { // chatSettings query comes after page load, this is needed to align the two states
    dispatch(chatSettings)
  }

  useEffect(() => {
    if (isEqual(state?.consent_form_require_locations, chatSettings?.consent_form_require_locations)) {
      setDisabled(true)
    } else {
      setDisabled(false)
    }
  }, [chatSettings, state])

  const generateDownload = (data: any): void => {
    const date = new Date().toString()
    const filename = 'Signals_User_Data_' + exportContact + '_' + DateFormat(date, 'isoDate') + '.csv'

    const blob = new Blob([data], { type: 'text/csv' })
    const elem = window.document.createElement('a')
    elem.href = window.URL.createObjectURL(blob)
    elem.download = filename
    document.body.appendChild(elem)

    elem.click()
    document.body.removeChild(elem)

    setExportContact('')
  }

  const exportData = (): void => {
    generateParticipantReport({ email: exportContact })
      .then((response) => {
        if (response === true) {
          setSnackState({
            open: true,
            variant: 'error',
            message: errorMsg(exportContact)
          })
        } else {
          generateDownload(response)
        }
      }
      )
  }

  const deleteData = (): void => {
    gdprDeleteContact({ email: deleteContact })
      .then((response) => {
        if (response === 'did not find') {
          setSnackState({
            open: true,
            variant: 'error',
            message: errorMsg(deleteContact)
          })
        } else {
          setSnackState({
            open: true,
            variant: 'success',
            message: `${deleteContact} and their corresponding data has been removed from our system`
          })
        }
      })
  }

  const saveSettings = (): void => {
    updateChatSettings({ state })
    setDisabled(true)
  }

  const saveProps = {
    saveText: 'Save Settings',
    disabled: disabled,
    save: saveSettings
  }

  document.title = 'Data Privacy | Signals'

  return (
    <AppPage
      title='Data Privacy'
      saveProps={saveProps}
    >
      <div className={classes.dataPrivacyPage}>
        <SettingsCard
          title='Consent Settings'
          subtitle='Before communicating with your visitors, you can require them to consent to your privacy policy. Certain areas require this.'
          alwaysExpanded
        >
          <div>
            <div className={classes.titleAndIconDiv}>
              <Typography>Require consent from:</Typography>
              <div className={classes.informationIcon}>
                <InformationIcon
                  message='Click to learn more about how this setting operates.'
                  link='https://help.getsignals.ai/article/r7epmbl4pt-data-privacy#consent_settings'
                />
              </div>
            </div>
            <div className={classes.select}>
              <Picker
                multiple
                selection={state?.consent_form_require_locations || []}
                setSelection={(value: any) => dispatch({ consent_form_require_locations: value })}
                objectType='location'
                label='Require consent from:'
                placeholder='No One'
              />
            </div>
          </div>
        </SettingsCard>
        <SettingsCard
          title='Consent Forms'
          alwaysExpanded
        >
          <div className={classes.tableDiv}>
            <ConsentFormTable />
          </div>
        </SettingsCard>
        <SettingsCard
          title='Data Management'
          alwaysExpanded
        >
          <div>
            <Typography variant='h2'>
              Export Contact Data
            </Typography>
            <Typography variant='subtitle2'>
              Retrieve all data Signals has associated with a contact's email address
            </Typography>
            <TextField
              value={exportContact}
              onChange={e => setExportContact(e.target.value)}
              {...textFieldProps}
              placeholder='example@example.com'
            />
            <Button
              {...buttonProps}
              onClick={exportData}
            >
              Export Data
            </Button>
          </div>
          <br />
          <div>
            <Typography variant='h2'>
              Permanently delete contact data
            </Typography>
            <Typography variant='subtitle2'>
              Delete all data Signals has associated with a contact's email address
            </Typography>
            <TextField
              value={deleteContact}
              onChange={e => setDeleteContact(e.target.value)}
              {...textFieldProps}
              placeholder='example@example.com'
            />

            <Button
              {...buttonProps}
              onClick={deleteData}
            >
              Delete Data
            </Button>
          </div>
        </SettingsCard>
      </div>
      <CustomizedSnackbar state={snackState} handler={setSnackState} />
    </AppPage>
  )
}
