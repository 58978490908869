import { useState, useMemo, useContext } from 'react'
import { Table, Fields, Actions } from 'cf-components/table/Table'
import { User } from 'classes/users'
import { Agent } from 'classes/agents'
import { UserPermissionModal } from './UserPermissionsModal'
import { availabilityIcon, mfaValues, calendarValues } from './UserTableIcons'
import ScheduleIcon from '@material-ui/icons/Schedule'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import { UserOutOfOfficeModal } from './UserOutOfOfficeModal'
import UserInviteModal from './InviteModal'
import { SessionContext } from 'session-context'
import { Team } from 'classes/classes'
import CustomizedSnackbar, { SnackState } from 'components/CustomizedSnackbar'
import SkeletonTableLoader from 'library/loading/skeleton/SkeletonTableLoader'
import SnapshotUpgradePromptModal, { usersUpgradeMessage } from 'pages/billing/SnapshotUpgradePromptModal'

interface UserListPageProps {
  teamMemberIds?: number[],
  teamID?: number
  actionRef: any
  isSnapshot: boolean
}

export function UserListPage ({ teamMemberIds, actionRef, isSnapshot, teamID }: UserListPageProps): JSX.Element {
  let prefix = ''
  if (window.location.hostname === 'beta.chatfunnels.com' || window.location.hostname === 'beta.getsignals.ai') {
    // this will allow the agent-profile service to send requests to beta instead of app
    prefix = '0/'
  }
  const { user } = useContext(SessionContext)
  const { data: users, isLoading: loadingUsers } = User.loadAll()
  const { data: agents, isLoading: loadingAgents } = Agent.loadAll()
  const userList = teamMemberIds ? users.list.filter((user: User) => teamMemberIds.includes(user.id)) : users.list
  const [editPermissions, setEditPermissionsModalData] = useState<User | null>(null)
  const [editOutOfOffice, setOutOfOfficeModalData] = useState<User | null>(null)
  const [editUserInvite, setUserInviteModalData] = useState(false)
  const [upgradeModalOpen, setUpgradeModalOpen] = useState(false)
  const [snackState, setSnackState] = useState<SnackState>({
    open: false,
    variant: 'success',
    message: 'Your settings have been saved'
  })
  const copyLinkToClipboard = (row: User) => {
    if (row.displayAgentProfilePage) {
      navigator.clipboard.writeText(`https://connect.getsignals.ai/${prefix}${row.agentProfilePageHandle}`)
      setSnackState({
        open: true,
        variant: 'success',
        message: 'Copied to clipboard!'
      })
    } else {
      setSnackState({
        open: true,
        variant: 'error',
        message: 'Unable to copy link: public profile page is disabled for this user!'
      })
    }
  }

  if (!teamMemberIds && actionRef) {
    actionRef.current = isSnapshot ? () => setUpgradeModalOpen(true) : () => setUserInviteModalData(true)
  }

  // This is done to combine the data from agents table (in channel manager) into the user class (in data fetch)
  const getUserRows = (users: any, agents: any): null => {
    if ((users.list).length !== 0 && (agents.list).length !== 0) {
      users.list.map((user: User) => {
        const agent = (agents.list).filter((agent: Agent) => agent.userID === user.id)
        if (agent.length !== 0) {
          const status = agent[0].availability
          const outOfOffice = agent[0].outOfOffice
          const id = agent[0].id
          user.participantID = id
          user.availability = status
          user.outOfOffice = outOfOffice
        }
        return user
      })
    }
    return null
  }

  useMemo(() => {
    getUserRows(users, agents)
  }, [users, agents])

  const handleToggle = ({ value, row }: { value: boolean, ID: User['id'], row: any }) => {
    const status = value ? 'available' : 'unavailable'
    Agent.update(row.participantID, status)
  }

  const deleteTeamMember = ({ row }: { row: any }): Promise<any> => {
    return Team.deleteTeamMember({ teamID: teamID, teamMemberID: row.id })
  }

  const fields: Fields<User> = [
    { name: 'name', type: 'avatar', label: 'Agent Name' },
    { name: 'role', type: 'text', label: 'Role' },
    { name: 'createdTimestamp', type: 'dateTime', label: 'Joined Date' },
    { name: 'lastSessionActivityDate', type: 'dateTime', label: 'Last Activity' },
    { name: 'calendarAccess', type: 'icon', data: calendarValues, label: 'Calendar' },
    { name: 'mfa', type: 'icon', data: mfaValues, label: 'MFA' },
    { name: 'availabilityValues', sortField: 'availabilitySort', type: 'toggleOrIcon', handleToggle: handleToggle, label: 'Availabilty', data: availabilityIcon }
  ]

  let actions: Actions<User> | undefined = [
    { type: 'edit', name: 'Edit Permissions', action: (row: User) => setEditPermissionsModalData(row) },
    { name: 'Out of Office Settings', icon: ScheduleIcon, action: (row: User) => setOutOfOfficeModalData(row) },
    { name: 'Copy Agent Profile Link', icon: FileCopyIcon, action: (row: User) => copyLinkToClipboard(row) }
  ]

  if (teamMemberIds) {
    actions.unshift({ type: 'delete', name: 'Remove From Team', action: (row: any) => deleteTeamMember({ row }) })
  } else { actions.unshift({ type: 'delete' }) }

  if (user.attributes.perms.strict_admin === false) { actions = undefined }

  if (loadingUsers || loadingAgents) {
    return (
      <div>
        <br />
        <br />
        <SkeletonTableLoader
          rowCount={5}
          types={['avatarNameIcon&Subtitle', 'label', 'label', 'label', 'number', 'number', 'smLabel']}
          columns='3fr 1fr 2fr 2fr 1fr 1fr 1fr'
          justifyCenter
          size='lg'
        />
      </div>
    )
  }

  return (
    <div>
      <Table
        rows={userList}
        fields={fields}
        columnStyles='3fr 1fr 2fr 2fr 1fr 1fr 1fr 50px'
        actions={actions}
      />
      {editPermissions &&
        <UserPermissionModal
          user={editPermissions}
          onHide={() => setEditPermissionsModalData(null)}
        />}
      {editOutOfOffice &&
        <UserOutOfOfficeModal
          user={editOutOfOffice}
          onHide={() => setOutOfOfficeModalData(null)}
        />}
      <UserInviteModal
        open={editUserInvite}
        close={() => setUserInviteModalData(false)}
      />
      <SnapshotUpgradePromptModal
        open={upgradeModalOpen}
        onClose={() => setUpgradeModalOpen(false)}
      >
        {usersUpgradeMessage}
      </SnapshotUpgradePromptModal>
      <CustomizedSnackbar state={snackState} handler={setSnackState} />
    </div>
  )
}
