import { AIDirective, defaultAIDirective, getTrainingData, saveTrainingData } from 'api/ai_settings'
import { getConversationEvents } from 'api/conversations'
import { Select } from 'cf-components/material-wrappers/MaterialComponents'
import { Modal, ModalSection } from 'library/Modal'
import { TextField, Typography } from 'library/materialUI'
import { useState, useEffect } from 'react'

interface CoachingModalProps {
  open: boolean
  setOpen: (value: boolean) => void
  coachingID: number
  participantID?: number
  onSave?: () => void
  onHide?: () => void
  file?: File
}

const tags = [
  { value: 'Pricing', label: 'Pricing' },
  { value: 'Intent Scoring', label: 'Intent Scoring' },
  { value: 'Response', label: 'Response' },
  { value: 'Behavior', label: 'Behavior' }
]

export default function CoachingModal ({ open, setOpen, coachingID, onSave, participantID, onHide, file }: CoachingModalProps) {
  const [canSave, setCanSave] = useState(false)
  const [coachingText, setCoachingText] = useState('')
  const [currentTags, setCurrentTags] = useState<string[]>([])
  const [trainingData, setTrainingData] = useState<AIDirective | null>(null)

  useEffect(() => {
    if (file) {
      handleFileUpload(file)
    }
  }, [file])

  const handleFileUpload = async (file: File) => {
    const reader = new FileReader()
    reader.onload = (event) => {
      const result = event.target?.result
      if (typeof result === 'string') {
        setCoachingText(result)
        setCanSave(true)
      }
    }
    reader.readAsText(file)
  }

  const handleSave = () => {
    const saveObject = trainingData ? { ...trainingData } : defaultAIDirective()
    saveObject.prompt = coachingText
    saveObject.tags = currentTags
    saveObject.type = 'coaching'
    saveObject.cloud_agent_id = parseInt(window.location.href.split('/').pop() || '')
    saveTrainingData(saveObject).then(() => {
      setOpen(false)
      if (onSave) {
        onSave()
      }
      setCoachingText('')
      setCurrentTags([])
    })
      .catch(err => {
        console.error('Error saving training data:', err)
      })
  }

  useEffect(() => {
    if (trainingData) {
      setTrainingData(
        {
          ...trainingData,
          prompt: coachingText
        }
      )
    }
    // eslint-disable-next-line
  }, [coachingText])

  useEffect(() => {
    if (coachingID) {
      getTrainingData(coachingID).then(response => {
        if (response.data) {
          setTrainingData(response.data.attributes)
          setCoachingText(response.data.attributes.prompt)
          setCurrentTags(response.data.attributes.tags || [])
        }
      })
    } else {
      setTrainingData(null)
    }
    // eslint-disable-next-line
  }, [coachingID])

  useEffect(() => {
    if (trainingData?.conversation_id && participantID) {
      getConversationEvents({ conversationID: trainingData.conversation_id }).then(response => {
        if (response.data) {
          let newContext = ''
          for (const conversationEvent of response.data) {
            if (conversationEvent.attributes.kind !== 'chat') {
              continue
            }
            if (conversationEvent.relationships.from_participant.data.id === participantID) {
              newContext += `{role: 'user', content: '${conversationEvent.attributes.body}'},`
            } else {
              newContext += `{role: 'assistant', content: '${conversationEvent.attributes.body}'},`
            }
          }
          trainingData.conversation_context = newContext
        }
      })
    }
    // eslint-disable-next-line
  }, [trainingData?.conversation_id, participantID])

  return (
    <Modal
      open={open}
      title={coachingID ? 'Edit Coaching' : 'Give Coaching'}
      onHide={() => {
        setOpen(false)
        setCoachingText('')
        setCurrentTags([])
        onHide && onHide()
      }}
      handleSave={handleSave}
      saveBtnText='Save'
      saveDisabled={!canSave}
      saveIcon='save'
    >
      <ModalSection>
        <div style={{ marginBottom: 10 }}>
          <Typography variant='subtitle1'>Tags</Typography>
          <Select
            value={currentTags}
            onChange={(value: string[]) => {
              setCurrentTags(value)
              setCanSave(coachingText !== '')
            }}
            multiple
            placeholder='Select Tags'
            options={tags}
          />
        </div>

        <Typography variant='subtitle1'>Description</Typography>
        <TextField
          variant='outlined'
          multiline
          maxRows={5}
          minRows={5}
          placeholder='If a buyer interacts with marketing content multiple....'
          value={coachingText}
          onChange={(e) => {
            setCoachingText(e.target.value)
            setCanSave(e.target.value !== '')
          }}
          fullWidth
        />
      </ModalSection>
    </Modal>
  )
}
