import { makeStyles } from '@material-ui/core/styles'
import { Router } from 'library/navigation/Route'
import AccountPage from 'pages/accounts/AccountDashboard/AccountPage'
import AccountManagementPage from 'pages/accounts/AccountManagement/AccountManagementPage'
import AccountSegmentPage from 'pages/accounts/AccountSegments/SegmentPage'
import AdminPortalRouter from 'pages/admin_portal/AdminPortalRouter'
import SequenceEditPage from 'pages/bot/bot-edit/SequenceEditPage'
import BotPage from 'pages/bot/BotPage'
import EmailSelectionPage from 'pages/bot/email/EmailSelectionPage'
import EmailWizard from 'pages/bot/email/EmailWizard'
import BotTestEdit from 'pages/bot_testing/BotTestEdit'
import BotTestingPage from 'pages/bot_testing/BotTestingPage'
import TestReportDashboard from 'pages/bot_testing/test-reports/TestReportDashboard'
import ChatPage from 'pages/chat/agent-chat/AgentChat'
import LiveViewPage from 'pages/chat/live-view/LiveViewPage'
import LandingDashboard from 'pages/dashboard/LandingDashboard'
import { EngagementManager } from 'pages/engagement/EngagementManager'
import FormPage from 'pages/FormPage'
import { FreeTrialBanner } from 'pages/FreeTrialBanner'
import OAuthSetup from 'pages/oauth/OAuthSetup'
import ContactManagementPage from 'pages/people/ContactManagementPage'
import ContactPage from 'pages/people/ContactPage/ContactPage'
import { PlayPage } from 'pages/plays/play/PlayPage'
import { SequencePage } from 'pages/plays/play/SequenceEditor'
import OverviewPage from 'pages/reports/OverviewPage'
import ChangeEmailConfirmation from 'pages/settings/EmailManagement/ChangeEmailConfirmation'
import SettingsPage from 'pages/settings/SettingsRouter'
import { TeamPage } from 'pages/users/Teams/TeamPage'
import UserDetailsPage from 'pages/users/UserDetailsPage'
import UserManagementPage from 'pages/users/UserManagement/UserManagementPage'
import UserRequestAcceptedPage from 'pages/users/UserRequestAcceptedPage'
import { Redirect, Route, useLocation } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  pages: {
    height: '100%',
    width: '100%',
    overflowY: 'auto',
    fontFamily: theme.typography.fontFamily
  }
}))

function useQueryString () {
  return new URLSearchParams(useLocation().search)
}

const HTMXComp = ({ match }: any) => {
  const query = useQueryString()
  const url = '/api/core/web/app/' + match.url.split('/v2/')[1] + '?' + query.toString()
  const id = match.params.page
  return (
    <div
      key={url}
      id={id}
      hx-get={url}
      hx-trigger='revealed'
    />
  )
}

export function MainAppRouting () {
  const classes = useStyles()
  const href = window.location.href
  if (href.includes('calendar_oauth')) {
    window.sessionStorage.setItem('onboardingConfirmation', 'calendar')
  } else if (href.includes('linkedin_oauth')) {
    window.sessionStorage.setItem('onboardingStep', 'photo')
  }
  return (
    <div className={classes.pages} id='main-pages'>
      <FreeTrialBanner />
      <Route path='/' exact><Redirect to='/dashboard' /></Route>
      <Route path='/calendar_oauth' exact><Redirect to='/settings/my_settings/my_calendar' /></Route>
      <Route path='/linkedin_oauth' exact><Redirect to='/dashboard' /></Route>
      <Route path='/quick-chat'><Redirect to='/dashboard' /></Route>
      <Router
        routes={[
          { path: '/v2/:page/:tab/:recordID', component: HTMXComp },
          { path: '/v2/:page/:tab', component: HTMXComp },
          { path: '/v2/:page', component: HTMXComp },
          { path: '/account_management', component: AccountManagementPage, exact: false },
          { path: '/account_segments/:segment_id', component: AccountSegmentPage },
          { path: '/accounts/:account_id', component: AccountPage },
          { path: '/admin_portal', component: AdminPortalRouter, exact: false },
          { path: '/bot_tests', component: BotTestingPage },
          { path: '/bot_tests/:bot_test_id', component: BotTestEdit },
          { path: '/bot_tests/dashboard/:bot_test_id', component: TestReportDashboard },
          { path: '/bots/:bot_id', component: BotPage, exact: false },
          { path: '/change-email-confirmation', component: ChangeEmailConfirmation, exact: false },
          { path: '/chat', component: ChatPage, exact: false },
          { path: '/contact_management', component: ContactManagementPage, exact: false },
          { path: '/contacts/:contact_id', component: ContactPage },
          { path: '/dashboard', component: LandingDashboard },
          { path: '/emails/edit/:email_id', component: EmailWizard },
          { path: '/emails/new', component: EmailSelectionPage },
          { path: '/engagement', component: EngagementManager, exact: false },
          { path: '/forms/:form_id', component: FormPage, exact: false },
          { path: '/integrations/oauth/setup', component: OAuthSetup },
          { path: '/live_view', component: LiveViewPage, exact: false },
          { path: '/playmaker', component: PlayPage },
          { path: '/plays/:sequenceID', component: SequencePage },
          { path: '/reports', component: OverviewPage, exact: false },
          { path: '/sequences/:sequence_id', component: SequenceEditPage, exact: false },
          { path: '/settings', component: SettingsPage, exact: false },
          { path: '/teams/:team_id', component: TeamPage, exact: false },
          { path: '/user_management', component: UserManagementPage, exact: false },
          { path: '/users/:user_id', component: UserDetailsPage },
          { path: '/user_requests/:user_request_id/invite', component: UserRequestAcceptedPage }
        ]}
      />
    </div>
  )
}
