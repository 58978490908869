import Account from 'classes/accounts';
import { LogoDiv } from 'pages/accounts/AccountDashboard/AccountHeader';
import DashboardCard from 'pages/dashboard/DashboardCard';
import { makeStyles } from '@material-ui/core/styles'
import { Icon } from 'library/materialUI';
import { useContext, useState } from 'react';
import { AccountPageContext } from 'pages/accounts/AccountDashboard/AccountPageProvider';
import { truncateString } from 'library/helpers';

const useStyles = makeStyles(theme => ({
  attribute: {
    marginBottom: '10px'
  },
  label: {
    color: '#808080',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '8px',
    fontSize: '15px'
  },
  value: {
    fontSize: '14px',
    marginLeft: '30px',
    marginTop: '5px'
  }
}))

interface ContactCompanyInfoProps {
  account: Account
}

export default function ContactCompanyInfo ({ account }: ContactCompanyInfoProps): JSX.Element {
  const classes = useStyles()
  const [seeMore, setSeeMore] = useState(false)
  const { setDetailsModalOpen, setLastSelectedDetail } = useContext(AccountPageContext)

  let hq = ''
  if (account) {
    if (account.addressCity && account.addressState) {
      hq = account.addressCity + ', ' + account.addressState
    } else if (account.addressCity) {
      hq = account.addressCity
    } else if (account.addressState) {
      hq = account.addressState
    } else if (account.addressCountryCode) {
      hq = account.addressCountryCode
    }
  } else {
    return <></>
  }

  return (
    <DashboardCard
      title='Company Information'
      noScrollbox
      titleAction={
        <div onClick={() => { setDetailsModalOpen(true); setLastSelectedDetail('name') }} style={{ border: '1px solid #D3D3D3', borderRadius: '5px', padding: '8px 15px 8px 15px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>
          <div>
            <Icon icon='edit' color='#808080' size='sm' />
          </div>
          <div style={{ marginLeft: '10px', fontSize: '14px' }}>
            Edit
          </div>
        </div>
      }
    >
      <div>
        <LogoDiv
          account={account}
        />
      </div>
      <div style={{ marginBottom: '10px' }}>
        <div>
          {!seeMore ? (
            truncateString(account.description, 172, true)
          ) : (
            account.description
          )}
        </div>
        <div style={{ marginBottom: '20px', color: '#808080', cursor: 'pointer' }}>
          <div onClick={() => setSeeMore(!seeMore)}>
            {seeMore ? 'See less' : 'See more'}
          </div>
        </div>
      </div>
      <div className={classes.attribute}>
        <div className={classes.label}>
          <Icon icon='domain' color='#808080' />
          <div>
            Industry
          </div>
        </div>
        <div className={classes.value}>
          {account.industry || 'No Industry Found'}
        </div>
      </div>
      <div className={classes.attribute}>
        <div className={classes.label}>
          <Icon icon='money' color='#808080' />
          <div>
            Annual Revenue
          </div>
        </div>
        <div className={classes.value}>
          {`$${account.annualRevenueFormatted}` || 'No Revenue Found'}
        </div>
      </div>
      <div className={classes.attribute}>
        <div className={classes.label}>
          <Icon icon='location' color='#808080' />
          <div>
            HQ Location
          </div>
        </div>
        <div className={classes.value}>
          {hq || 'Location Unknown'}
        </div>
      </div>
      <div className={classes.attribute}>
        <div className={classes.label}>
          <Icon icon='users' color='#808080' />
          <div>
            Employee Count
          </div>
        </div>
        <div className={classes.value}>
          {account.employeeCountFormatted || 'Employee Count Unknown'}
        </div>
      </div>
    </DashboardCard>
  )
}
