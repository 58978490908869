/* eslint-disable @typescript-eslint/naming-convention */
import { useContext, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { SessionContext, initialState } from 'session-context'
import DashboardContents from './DashboardContents'
import PotentialAccountModal from './PotentialAccountModal'
import { updateAccount, createIntegrationAccount } from 'api/account_management'
import Account from 'classes/accounts'
import { CRMContext } from 'crm-context'
import { EVENT_TYPE, Emitter } from 'emitter'
import IntroModal from 'library/IntroModal'
import { saveSettings } from 'api/user_display_settings'
// Hiding onboarding ftux for now
// import FTUX from 'cf-components/FTUX'
// import { DisplaySettings } from 'classes/displaySettings'
// import DashboardOnboarding from 'cf-components/OnboardingComponents/DashboardOnboarding'
import CustomTabIntroGif from 'img/CustomTabsIntro.gif'

const useStyles = makeStyles(theme => ({
  landingDashboard: {
    fontFamily: 'Poppins, sans serif',
    backgroundColor: '#F5F5F5',
    minHeight: '100vh',
    position: 'relative',
    overflowX: 'hidden',
    overflowY: 'auto',
    width: '100%',
    minWidth: '100%',
    paddingTop: 20
  },
  contents: {
    position: 'absolute',
    paddingLeft: 20,
    width: 'calc(100% - 40px)',
    minWidth: '1220px'
  },
  splash: {
    position: 'absolute',
    width: 'calc(100vw - 60px)',
    height: 300
  },
  welcomeDiv: {
    display: 'flex',
    alignItems: 'center',
    color: 'black',
    fontFamily: 'poppins',
    fontWeight: 600,
    marginBottom: 7
  },
  headerCards: {
    display: 'grid',
    gridGap: 20,
    cursor: 'pointer',
    gridTemplateColumns: '2fr 2fr 3fr'
  },
  filterContainer: {
    display: 'flex',
    flexDirection: 'row',
    left: 'auto',
    justifyContent: 'right',
    alignItems: 'center'
  },
  divider: {
    height: '2px',
    width: 'maxContent',
    backgroundColor: '#00000030',
    margin: '18px'
  },
  spacer: {
    width: '100%'
  },
  grayText: {
    color: '#00000080',
    fontFamily: 'poppins'
  }
}))

function LandingDashboard () {
  const { userSettings } = useContext(SessionContext)
  const { crmIntegrationName } = useContext(CRMContext)
  const classes = useStyles()
  const [modalOpen, setModalOpen] = useState<'Trending' | 'Recent' | 'Potential' | 'ICP' | null>(null)
  const [potentialAccountModalOpen, setPotentialAccountModalOpen] = useState(false)
  const [modalDomain, setModalDomain] = useState('')
  const [accountAdded, setAccountAdded] = useState([])
  const [startIntro, setStartIntro] = useState(false)
  const [hideIntro, setHideIntro] = useState(false)
  const { data: acc, isLoading: accLoading } = Account.loadOne(modalDomain)
  let mode = initialState['abe-dashboard'].settings.mode

  const hash = window.location.hash

  useEffect(() => {
    // Used in ABE Daily Emails
    if (hash.includes('trending_accounts')) {
      setModalOpen('Trending')
    }
    if (hash.includes('potential_accounts')) {
      setModalOpen('Potential')
    }
    if (hash.includes('last_accounts')) {
      setModalOpen('Recent')
    }
  }, [hash])

  useEffect(() => {
    const url = window.location.href
    const queryString = url.includes('?') ? url.split('?')[1] : ''
    const queryParams = new URLSearchParams(queryString)
    const subject = queryParams.get('subject')

    if (subject) {
      Emitter.emit(EVENT_TYPE.HELP_CENTER, { path: 'home/feedback/Support History', customProps: { pathOnLoad: subject } })
    }

    const redirectURL = sessionStorage.getItem('redirect_url')
    if (redirectURL) {
      sessionStorage.removeItem('redirect_url')
      window.location.href = redirectURL
    }
  }, [])

  if (userSettings) {
    const dashboardSettings = userSettings['abe-dashboard'].settings
    mode = dashboardSettings.mode
  }

  const handleSubmitPotentialAccount = (accountState: any, additionalFields: any) => {
    const temp_comp = { ...accountState, domain: accountState.id, id: null }
    updateAccount({ account: isNaN(accountState.id) ? temp_comp : accountState })
      .then(response => {
        if (response.data && crmIntegrationName) {
          createIntegrationAccount({ domain: response.data.attributes.domain, additionalFields }).then(() => {
            setPotentialAccountModalOpen(false)
          })
        } else {
          setPotentialAccountModalOpen(false)
        }
      })
  }

  const handleIntro = (start: boolean) => {
    saveSettings({ kind: 'dashboard-intro', settings: { show: false }, post: 'PATCH' })
    setHideIntro(true)
    setStartIntro(start)
  }

  // Hiding onboarding ftux for now
  // const promptList = [
  //   {
  //     anchorID: 'dashboard-tabs',
  //     xOffset: 120,
  //     yOffset: 0,
  //     updateFunction: () => {
  //       DisplaySettings.update({ page: 'ftux', type: 'dashboard-onboarding', settings: { ...userSettings.ftux.settings['dashboard-onboarding'], new_dashboard_tabs: { complete: true }, add_view_button: { complete: true }, edit_view_button: { complete: true } } })
  //     },
  //     anchors: [
  //       {
  //         title: 'Rearrange your tabs',
  //         body: (
  //           <div>
  //             <div>
  //               <span className={classes.grayText}>You can now click and drag to reorder your pinned views</span>
  //             </div>
  //           </div>
  //         ),
  //         anchorID: 'dashboard-tabs',
  //         anchorPos: 'left',
  //         anchorOriginHorizontal: 'right',
  //         anchorOriginVertical: 'top',
  //         transformOriginHorizontal: 'left',
  //         transformOriginVertical: 'top',
  //         arrowOffset: '15px'
  //       },
  //       {
  //         title: 'Filter your dashboard',
  //         body: (
  //           <div>
  //             <div>
  //               <span className={classes.grayText}>Filter what you see on your dashboard by either creating a new view, or by duplicating and adding filters to an existing view.</span>
  //             </div>
  //           </div>
  //         ),
  //         anchorID: 'add_view_button',
  //         anchorPos: 'right',
  //         anchorOriginHorizontal: -45,
  //         anchorOriginVertical: -30,
  //         transformOriginHorizontal: 'right',
  //         transformOriginVertical: 'top',
  //         arrowOffset: '30px'
  //       },
  //       {
  //         title: 'Manage your views',
  //         body: (
  //           <div>
  //             <div>
  //               <span className={classes.grayText}>Find views you’ve already created here, and add or remove them from your dashboard.</span>
  //             </div>
  //           </div>
  //         ),
  //         anchorID: 'edit_views_button',
  //         anchorPos: 'right',
  //         anchorOriginHorizontal: -45,
  //         anchorOriginVertical: -30,
  //         transformOriginHorizontal: 'right',
  //         transformOriginVertical: 'top',
  //         arrowOffset: '30px'
  //       }
  //     ]
  //   }
  // ]

  return (
    <div className={classes.landingDashboard}>
      <div id='dashboard-row' className={classes.contents}>
        <DashboardContents
          mode={mode}
          modalOpen={modalOpen}
          setPotentialAccountModalOpen={setPotentialAccountModalOpen}
          setModalDomain={setModalDomain}
          accountAdded={accountAdded}
        />
        <PotentialAccountModal
          domain={modalDomain}
          account={acc}
          accLoading={accLoading}
          open={potentialAccountModalOpen}
          onHide={() => setPotentialAccountModalOpen(false)}
          setPotentialAccountModalOpen={setPotentialAccountModalOpen}
          handleSubmit={handleSubmitPotentialAccount}
          accountAdded={accountAdded}
          setAccountAdded={setAccountAdded}
        />
        {/* Intro modal for new dashboard remove after enough time has passed since update */}
        {userSettings && userSettings['dashboard-intro']?.settings?.show && !startIntro && !hideIntro && (
          <IntroModal
            open
            onClose={() => handleIntro(false)}
            onAction={() => handleIntro(true)}
            onSkip={() => setHideIntro(true)}
            actionBtnText="See what's new"
            title="We've refreshed the dashboard"
            subtitle='You now have more control over what you see on your dashboard with new rearrangeable custom tabs!'
            imgSrc={CustomTabIntroGif}
          />
        )}
        {/* Hiding dashboard onboarding icons for now */}
        {/* {startIntro && promptList.map((prompt, index) => {
          return (
            <FTUX
              key={index}
              page='dashboard'
              anchors={prompt.anchors}
              updateFunction={prompt.updateFunction}
              dim
            />
          )
        })} */}
        {/* Dashboard Onboarding for new users */}
        {/* <div style={{ position: 'fixed', top: 0, left: 0 }}>
          <DashboardOnboarding />
        </div> */}
      </div>
    </div>
  )
}

export default LandingDashboard
