import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useLocation, useHistory } from 'react-router-dom'
import CircuitBackground from 'img/circuit.png'
import { Button, Card } from '@material-ui/core'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import { Icon } from 'library/materialUI'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'relative',
    background: `linear-gradient(rgba(255,255,255,.8), rgba(255,255,255,.8)), url(${CircuitBackground})`,
    height: '100%',
    backgroundSize: 'contain'
  },
  card: {
    paddingBottom: 50,
    paddingTop: 50,
    borderRadius: 10,
    marginTop: 15,
    display: 'flex',
    maxWidth: 950,
    marginBottom: 100,
    marginRight: 30,
    marginLeft: 30,
    minWidth: 250
  },
  logo: {
    height: 65,
    marginTop: 25
  },
  formContainer: {
    paddingLeft: 20,
    paddingRight: 20
  },
  formContainerMobile: {
    width: '100%',
    paddingLeft: 40,
    paddingRight: 40
  },
  title: {
    fontSize: 30,
    textAlign: 'center',
    fontWeight: 100
  },
  mobileTitle: {
    fontSize: 24,
    textAlign: 'center',
    fontWeight: 100
  },
  errorMessage: {
    fontSize: 20,
    color: 'red'
  }
}))

function useQuery () {
  return new URLSearchParams(useLocation().search)
}

const VerifyAccountPage = props => {
  const classes = useStyles()
  const width = 'auto'
  const small = useMediaQuery('(max-width: 1000px)')
  const query = useQuery()
  const [verified, setVerified] = useState(false)
  const [errors, setErrors] = useState(false)
  const myStyle = { ...props.style, width: width }
  const history = useHistory()

  const navigateToLogin = () => {
    history.push('/')
  }

  const verifyEmail = token => {
    fetch('/api/auth/signup:verify', {
      method: 'POST',
      headers: { 'Content-Type': 'application/vnd.api+json' },
      body: JSON.stringify({
        token: token
      })
    })
      .then(response => response.json())
      .then(response => {
        if (response.ok) {
          setVerified(true)
        } else if (response.errors) {
          setErrors(response.errors)
        }
      })
  }

  useEffect(() => {
    const token = query.get('token')
    if (token) {
      verifyEmail(token)
    }
  // eslint-disable-next-line
  }, [])

  return (
    <>
      <div className={classes.container}>
        <div style={classes.logo}>
          <Icon icon='logo' />
        </div>
        <Card raised className={classes.card} style={myStyle}>
          <div className={small ? classes.formContainerMobile : classes.formContainer}>
            {errors ? (
              <div>
                {errors.map((error, index) => (
                  <div key={index} style={{ textAlign: 'center' }}>
                    <div className={classes.errorMessage}>
                      {error.detail}
                    </div>
                    <div style={{ fontWeight: 100, marginTop: 10, marginBottom: 30 }}>
                      Try to login to Signals to send a new verification email
                    </div>
                    <Button
                      variant='outlined'
                      onClick={navigateToLogin}
                      color='primary'
                    >Go to Login Page
                    </Button>
                  </div>
                ))}
              </div>
            ) : verified ? (
              <div style={{ textAlign: 'center' }}>
                <div>
                  <CheckCircleOutlineIcon fontSize='large' style={{ color: '#2fb52f' }} />
                </div>
                <div style={{ fontSize: 20, fontWeight: 100, marginTop: 10, marginBottom: 30 }}>
                  Your account has been verified
                </div>
                <div>
                  <Button
                    variant='outlined'
                    onClick={navigateToLogin}
                    color='primary'
                  >Log In to Signals
                  </Button>
                </div>
              </div>
            )
              : (
                <div style={{ fontSize: 28, textAlign: 'center', fontWeight: 100, padding: '0px 50px' }}>
                  Verifying...
                </div>
              )}
          </div>
        </Card>
      </div>
    </>
  )
}

export default VerifyAccountPage
