/* eslint-disable @typescript-eslint/naming-convention */
import { getTenantSummary } from 'api/admin_only/tenants'
import AppPage from 'cf-components/AppPage'
import Loader from 'library/loading/Loader'
import { useEffect, useState } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { AdminTenantOverviewPage } from './AdminTenantOverviewPage'
import { AdminUserListPage } from './AdminUserListPage'
import { User } from 'classes/users'

interface matchParams {
  params: {
    tenantId: string
  }
}

export interface tenantParams {
  attributes: TenantSummary // types need to be updated
}

interface TenantSummary {
  name: any
  session_length: any
  lock_time: any
  attempts_allowed: any
  session_length_minutes: any
  mfa_requirement: any
  onboarding_complete_timestamp: any
  onboarding_integrations: any
  account_manager_id: any
  point_of_contact_id: any
}

export const AdminSingleTenantPage = (): JSX.Element => {
  const match: matchParams | null = useRouteMatch('/admin_portal/tenants/:tenantId')
  let tenantId: number | null = null
  if (match) {
    tenantId = parseInt(match.params.tenantId)
  }
  const [tenantSummary, setTenantSummary] = useState<tenantParams | null>(null)
  const { data: users, isLoading } = User.loadTenantSpecific(tenantId)

  useEffect(() => {
    setTenantSummary(null)
    getTenantSummary(tenantId)
      .then(response => {
        setTenantSummary(response.data)
      })
  }, [tenantId])

  const name = tenantSummary ? tenantSummary.attributes.name || 'Tenant ' + tenantId : ''

  const breadcrumbs = [
    { link: '/admin_portal/tenants', text: 'Tenant List' },
    { link: null, text: 'Tenant Overview' }
  ]

  const tabs = [
    { name: 'Tenant Overview', url: '/admin_portal/tenants/' + tenantId },
    { name: 'Users', url: `/admin_portal/tenants/${tenantId}/users` }
  ]

  return !isLoading && tenantSummary ? (
    <AppPage
      title={name}
      padding={0}
      breadcrumbs={breadcrumbs}
      tabs={tabs}
    >
      <AdminTenantOverviewPage tenantId={tenantId} users={users.list} tenantSummary={tenantSummary} />
      <AdminUserListPage users={users.list} />
    </AppPage>
  ) : (<Loader type='spinner' />)
}
